import React from 'react'
import PageLayout from '../../components/page-layout'
import { AdminList } from './list'
import { Button, Space } from 'antd'
import { useNavigate } from 'react-router'
import { RoutePath } from '../../model/enum/route-path'
import { PlusOutlined } from '@ant-design/icons'

const AdminPage = (): JSX.Element => {
    const navigate = useNavigate()
    return (
        <PageLayout
            title="ระบบจัดการ Admin"
            action={
                <Space>
                    <Button
                        onClick={() => navigate(`/${RoutePath.ADMIN}/create`)}
                        type="primary"
                    >
                        <PlusOutlined />
                        เพิ่ม Admin
                    </Button>
                </Space>
            }
        >
            <AdminList />
        </PageLayout>
    )
}

export default AdminPage
