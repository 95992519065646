import { FormInstance } from 'antd'
import React from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import styled from 'styled-components'

const InputDetailStyle = styled(ReactQuill)`
    div.ql-toolbar {
        border-radius: 8px 8px 0 0;
    }
    div.ql-container {
        font-size: 16px;
        border-radius: 0 0 8px 8px;
        min-height: 150px;
    }
    div.ql-editor.ql-blank::before {
        font-family: inter;
        font-weight: 200;
        font-style: normal;
        font-size: 16px;
        color: #cfcfcf;
    }
`

interface InputDetailProps {
    form: FormInstance
    name: string | (string | number)[]
    placeholder: string
}

const InputDetail: React.FC<InputDetailProps> = ({
    form,
    name,
    placeholder,
}) => {
    const valueForm = form.getFieldValue(name)
    const handleChange = (content: string) => {
        form.setFieldValue(name, content)
    }

    const toolbarOptions = [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ['bold', 'italic', 'underline'],
        [{ list: 'ordered' }, { list: 'bullet' }],
    ]

    const modules = {
        toolbar: toolbarOptions,
        clipboard: {
            matchVisual: false,
        },
    }
    return (
        <InputDetailStyle
            modules={modules}
            value={valueForm}
            onChange={handleChange}
            placeholder={placeholder}
        />
    )
}

export default InputDetail
