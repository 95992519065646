import React from 'react'
import { Form, FormInstance } from 'antd'
import { useNavigate, useParams } from 'react-router'
import { ProductService } from '../../../../service/product.service'
import { ProductRequest } from '../../../../model/interface/product'
import { notificationMessage } from '../../../../components/notification'
import { useAuth } from '../../../../context/Profile.context'
import ConfirmationModal, {
    ActionType,
} from '../../../../components/confirmationModal'
import { MasterDataService } from '../../../../service/masterData.service'
import { AxiosError } from 'axios'

interface FormProductReturnType {
    form: FormInstance
    isCreate: boolean
    disabled: boolean
    initialValues?: ProductRequest
    options: { value: string; label: string }[]
    confirmCreateEditProduct: (
        isCreate: boolean,
        data: ProductRequest,
        icon: React.ReactNode
    ) => Promise<void>
    confirmCancelProduct: (icon: React.ReactNode) => Promise<void>
}

function useFormProduct(): FormProductReturnType {
    const auth = useAuth()
    const navigate = useNavigate()
    const [form] = Form.useForm()
    const { id } = useParams()

    const isCreate = window.location.href.split('/').pop() === 'create'

    const [disabled, setDisabled] = React.useState(false)
    const [initialValues, setInitialValues] = React.useState<ProductRequest>()
    const [options, setOptions] = React.useState<
        { value: string; label: string }[]
    >([])

    const fetchInitialValue = React.useCallback(async () => {
        if (id && !isCreate) {
            try {
                const initialValueResponse =
                    await ProductService.getProductById(id)
                setInitialValues({
                    ...initialValueResponse,
                    categoryId: initialValueResponse.category.id,
                })
            } catch (error) {
                navigate('/product')
                notificationMessage({
                    type: 'error',
                    message: `ไม่สามารถดึงข้อมูลสินค้า ID: ${id} ได้`,
                })
            }
        }
    }, [id, isCreate, navigate])
    React.useEffect(() => {
        fetchInitialValue()
    }, [fetchInitialValue])

    const fetchOptionsData = React.useCallback(async () => {
        try {
            const response =
                await MasterDataService.getMasterDataProductCategory()
            const newOptions = response.data.map((item) => ({
                value: item.id,
                label: item.masterDataName,
            }))
            setOptions(newOptions)
        } catch (error) {
            console.error('Error fetching data:', error)
        }
    }, [])
    React.useEffect(() => {
        fetchOptionsData()
    }, [fetchOptionsData])

    React.useEffect(() => {
        form.setFieldsValue(initialValues)
    }, [form, initialValues])

    const pushCreateProduct = async (values: ProductRequest) => {
        try {
            const res = await ProductService.postProduct(values)
            notificationMessage({
                type: 'success',
                message: res.message,
            })
        } catch (error) {
            notificationMessage({
                type: 'error',
                message: 'สร้างข้อมูลไม่สำเร็จ',
            })
            console.error('Error occurred while posting product:', error)
        }
    }

    const putEditProduct = async (id: string, values: ProductRequest) => {
        try {
            const res = await ProductService.putProduct(id, values)
            notificationMessage({
                type: 'success',
                message: res.message,
            })
        } catch (error) {
            const axiosError = error as AxiosError<{
                code: number
                message: string
            }>
            switch (axiosError?.response?.data.message) {
                case 'this product is in product another':
                    return notificationMessage({
                        type: 'error',
                        message:
                            'แก้ไขข้อมูลไม่สำเร็จ เนื่องจากมีสินค้านี้อยู่ในสินค้าอื่นๆ',
                    })
                case 'this product is in product recommendation':
                    return notificationMessage({
                        type: 'error',
                        message:
                            'แก้ไขข้อมูลไม่สำเร็จ เนื่องจากมีสินค้านี้อยู่ในสินค้าแนะนำ',
                    })
            }
        }
    }

    const confirmCreateEditProduct = async (
        isCreate: boolean,
        data: ProductRequest
    ) => {
        const profile = auth.profile || { email: '', userId: '' }
        const body: ProductRequest = {
            ...data,
            createBy: {
                email: profile.email,
                id: profile.userId,
            },
        }

        ConfirmationModal({
            mode: `${isCreate ? 'create' : 'update'}` as ActionType,
            title: 'สินค้า',
            async onOk() {
                try {
                    setDisabled(true)
                    if (isCreate) {
                        await pushCreateProduct(body)
                    } else {
                        if (id) {
                            await putEditProduct(id, body)
                        }
                    }
                    navigate('/product')
                } catch (error) {
                    console.error(
                        `Error ${isCreate ? 'create' : 'update'} product:`,
                        error
                    )
                }
            },
        })
    }

    const confirmCancelProduct = async () => {
        ConfirmationModal({
            mode: `${isCreate ? 'cancelCreate' : 'cancelUpdate'}` as ActionType,
            title: 'สินค้า',
            onOk() {
                navigate('/product')
                notificationMessage({
                    type: 'success',
                    message: 'ยกเลิกสำเร็จ',
                })
            },
        })
    }

    return {
        form,
        isCreate,
        disabled,
        initialValues,
        options,
        confirmCreateEditProduct,
        confirmCancelProduct,
    }
}

export default useFormProduct
