import React, { useEffect, useState } from 'react'
import { Tabs } from 'antd'
import { MasterDataItem } from '../../../../model/interface/masterData'
import { MasterDataService } from '../../../../service/masterData.service'

interface TabCategoryProps {
    onTabsLoaded: (tabs: { index: number; id: string }[]) => void
    onChange: (index: number, id: string) => void
}

const TabCategory = ({
    onTabsLoaded,
    onChange,
}: TabCategoryProps): JSX.Element => {
    const [dataSource, setDataSource] = useState<MasterDataItem[]>([])

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response =
                    await MasterDataService.getMasterDataProductCategory()
                setDataSource(response.data)
                const tabs = response.data.map((item, index) => ({
                    index,
                    id: item.id,
                }))
                onTabsLoaded(tabs)
            } catch (error) {
                console.error('Error fetching data:', error)
            }
        }
        fetchData()
    }, [])

    const items = dataSource.map((item, index) => ({
        label: item.masterDataName,
        key: item.id,
        index: index,
    }))

    const handleTabChange = (key: string | number) => {
        if (typeof key === 'string') {
            const index = dataSource.findIndex((item) => item.id === key)
            if (index !== -1) {
                onChange(index, key as string)
            }
        }
    }

    return (
        <>
            <Tabs
                defaultActiveKey="0"
                items={items}
                onChange={handleTabChange}
            />
        </>
    )
}

export default TabCategory
