import React, { useEffect } from 'react'
import { IAdmin } from '../../../model/interface/admin'
import { Button, Spin, Table } from 'antd'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import ConfirmationModal, {
    ActionType,
} from '../../../components/confirmationModal'
import { notificationMessage } from '../../../components/notification'
import { useNavigate } from 'react-router'
import { AdminService } from '../../../service/admin.service'
import { ColumnsType } from 'antd/es/table'
import { detoken } from '../../../context/Profile.context'

export const AdminList = (): JSX.Element => {
    const token = localStorage.getItem('access_token')
    const userId = token ? detoken(token).userId : ''
    const navigate = useNavigate()
    const onEdit = (id: string) => navigate(`/admin/edit/${id}`)
    const [dataSource, setDataSource] = React.useState<IAdmin[]>([])
    const [loading, setLoading] = React.useState<boolean>(false)

    const handleDelete = async (id: string) => {
        try {
            await AdminService.deleteAdmin(id).then(() => {
                notificationMessage({
                    type: 'success',
                    message: 'ลบ Admin สำเร็จ',
                })
            })
            fetchData()
        } catch (error) {
            notificationMessage({
                type: 'error',
                message: 'ลบ Admin ไม่สำเร็จ',
            })
            console.error('Error deleting admin:', error)
        }
    }

    const showDeleteConfirm = (id: string) => {
        ConfirmationModal({
            mode: 'delete' as ActionType,
            title: 'Admin',
            onOk: () => handleDelete(id),
            okType: 'danger',
        })
    }

    const fetchData = async () => {
        try {
            setLoading(true)
            await AdminService.getAllAdmin().then((res) => {
                setDataSource(res.data)
            })
            setLoading(false)
        } catch {
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchData()
    }, [])

    const columns: ColumnsType<IAdmin> = [
        {
            title: 'ชื่อจริง',
            dataIndex: 'firstName',
            width: '200px',
            render: (_, record) => (
                <div
                    style={{ lineBreak: 'auto', width: '100px' }}
                    key={record.userId}
                >
                    {record.firstName}
                </div>
            ),
        },
        {
            title: 'นามสกุล',
            dataIndex: 'lastName',
            width: '200px',
            render: (_, record) => (
                <div
                    style={{ lineBreak: 'auto', width: '100px' }}
                    key={record.userId}
                >
                    {record.lastName}
                </div>
            ),
        },
        {
            title: 'E-mail',
            dataIndex: 'email',
            width: '200px',
            render: (_, record) => (
                <div
                    style={{ lineBreak: 'auto', width: '200px' }}
                    key={record.userId}
                >
                    {record.email}
                </div>
            ),
        },
        {
            title: 'Password',
            dataIndex: 'bannerName',
            width: '200px',
            render: (_, record) => (
                <div
                    style={{ lineBreak: 'auto', width: '100px' }}
                    key={record.userId}
                >
                    {record.password ? '********' : 'ไม่มีข้อมูล'}
                </div>
            ),
        },
        {
            title: '',
            dataIndex: 'action',
            key: 'action',
            width: '200px',
            render: (_: string, admin) => (
                <div>
                    <Button
                        type="dashed"
                        size="middle"
                        style={{ marginRight: '10px' }}
                        onClick={() => onEdit(admin.userId)}
                    >
                        <EditOutlined style={{ fontSize: '18px' }} />
                        แก้ไข
                    </Button>
                    <Button
                        danger
                        type="dashed"
                        size="middle"
                        style={{ color: 'red' }}
                        disabled={userId === admin.userId}
                        onClick={() => {
                            showDeleteConfirm(admin.userId)
                        }}
                    >
                        <DeleteOutlined style={{ fontSize: '18px' }} />
                        ลบ
                    </Button>
                </div>
            ),
        },
    ]

    return (
        <>
            <Spin spinning={loading} />
            <Table
                rowKey="userId"
                columns={columns}
                dataSource={dataSource}
                pagination={false}
            />
        </>
    )
}
