import React, { useEffect, useState } from 'react'
import {
    Button,
    Col,
    Empty,
    Input,
    Row,
    Select,
    Table,
    TableColumnsType,
    Tooltip,
    // Tooltip,
} from 'antd'
import { MasterDataService } from '../../../service/masterData.service'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import {
    DataType,
    MasterDataItem,
    MasterDataItemResponse,
    MasterDataType,
    MasterDataTypeResponse,
} from '../../../model/interface/masterData'
import { notificationMessage } from '../../../components/notification'
import { RoutePath } from '../../../model/enum/route-path'
import { useNavigate } from 'react-router'
import debounce from 'lodash/debounce'
import { Image } from 'antd'
import ConfirmationModal, {
    ActionType,
} from '../../../components/confirmationModal'
import { AxiosError } from 'axios'

const { Search } = Input

export const mapData = (dataResponse: MasterDataItem[]): DataType[] => {
    return dataResponse.map((item) => ({
        key: item.id,
        typeName: item.typeName,
        typeID: item.typeId,
        imageID: item.bannerId,
        name: item.masterDataName,
        description: item.description,
        banner: item.banner,
    }))
}

const MasterDataComponent = (): JSX.Element => {
    const [searchValue, setSearchValue] = useState('')
    const [data, setData] = useState<DataType[] | null>([])
    const [dataType, setDataType] = useState<MasterDataType[]>([])
    const navigate = useNavigate()
    const [total, setTotal] = useState(0)
    const [current, setCurrent] = useState(1)
    const columns: TableColumnsType<DataType> = [
        {
            title: 'ประเภท',
            dataIndex: 'typeName',
            key: 'type',
            width: '20%',
        },
        {
            title: 'ชื่อ',
            dataIndex: 'name',
            key: 'name',
            width: '20%',
            // sorter: (a, b) => a.name.localeCompare(b.name),
        },
        {
            title: 'คำอธิบาย',
            dataIndex: 'description',
            key: 'description',
            width: '20%',
            render: (text: string) => (
                <span>
                    {text.length > 30 ? (
                        <Tooltip title={text} color="blue">
                            {`${text.slice(0, 20)}...`}
                        </Tooltip>
                    ) : (
                        text
                    )}
                </span>
            ),
        },
        {
            title: 'รูป Banner',
            dataIndex: 'banner',
            key: 'banner',
            width: '20%',
            align: 'center',
            render: (banner: string) =>
                banner ? (
                    <Image
                        height={32}
                        style={{ aspectRatio: '16/9', objectFit: 'cover' }}
                        src={banner}
                    />
                ) : (
                    '-'
                ),
        },
        {
            title: '',
            dataIndex: 'action',
            key: 'action',
            width: '20%',
            render: (_: string, data) => (
                <div>
                    <Button
                        type="dashed"
                        size="middle"
                        style={{ marginRight: '10px' }}
                        onClick={() => handleEdit(data.key)}
                    >
                        <EditOutlined style={{ fontSize: '18px' }} />
                        แก้ไข
                    </Button>
                    <Button
                        danger
                        type="dashed"
                        size="middle"
                        style={{ color: 'red' }}
                        onClick={() => showDeleteConfirm(data.key)}
                    >
                        <DeleteOutlined style={{ fontSize: '18px' }} />
                        ลบ
                    </Button>
                </div>
            ),
        },
    ]

    const [selectedValue, setSelectedValue] = useState('')

    const handleSelectChange = (value: string) => {
        const selected = value === undefined ? '' : value
        setSelectedValue(selected)
        console.log('Selected value:', selected)
    }

    const handlePageChange = (page: number) => {
        setCurrent(page)
    }

    const handleEdit = (editData: string) => {
        navigate(`/${RoutePath.MASTER}/edit/${editData}`)
    }

    const handleDelete = async (deleteId: string) => {
        try {
            await MasterDataService.deleteMasterDataType(deleteId)
            fetchData()
            notificationMessage({
                type: 'success',
                message: 'ลบ Master Data สำเร็จ',
            })
        } catch (error) {
            const axiosError = error as AxiosError<{
                code: number
                message: string
            }>
            switch (axiosError?.response?.data.message) {
                case 'item is referenced by existing MasterDataItem in Product':
                    return notificationMessage({
                        type: 'error',
                        message:
                            'ลบ Master Data ไม่สำเร็จ เนื่องจากมีสินค้าที่อยู่ใน Master Data นี้อยู่',
                    })
                default:
                    return notificationMessage({
                        type: 'error',
                        message: 'ลบ Master Data ไม่สำเร็จ',
                    })
            }
        }
    }

    const fetchDataType = async () => {
        try {
            const responseData: MasterDataTypeResponse =
                await MasterDataService.getAllMasterDataType()
            setDataType(responseData.data)
        } catch (error) {
            console.error('Error fetching data:', error)
        }
    }

    const fetchData = async () => {
        try {
            const responseData: MasterDataItemResponse =
                await MasterDataService.getAllMasterData({
                    search: searchValue,
                    masterDataType: selectedValue,
                    page: current,
                    pageSize: 10,
                    total: total,
                })
            setTotal(responseData.total)
            if (responseData.data !== null) {
                const mappedData: DataType[] = mapData(responseData.data)
                setData(mappedData)
            } else {
                setData(null)
            }
        } catch (error) {
            console.error('Error fetching data:', error)
        }
    }

    const showDeleteConfirm = (id: string) => {
        ConfirmationModal({
            mode: 'delete' as ActionType,
            title: 'Master Data',
            onOk: () => handleDelete(id),
            okType: 'danger',
        })
    }

    useEffect(() => {
        fetchData()
        fetchDataType()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchValue, current, selectedValue])

    const handleSearch = debounce((value: string) => {
        setSearchValue(value)
    }, 300)

    return (
        <>
            <Row gutter={[16, 0]}>
                <Col span={12}>
                    <Select
                        style={{ width: '100%' }}
                        showSearch
                        placeholder="กรองด้วยประเภทของ Master Data"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            (option?.label ?? '').includes(input)
                        }
                        filterSort={(optionA, optionB) =>
                            (optionA?.label ?? '')
                                .toLowerCase()
                                .localeCompare(
                                    (optionB?.label ?? '').toLowerCase()
                                )
                        }
                        options={dataType.map((item: MasterDataType) => ({
                            value: item.id,
                            label: item.typeName,
                        }))}
                        onChange={handleSelectChange}
                        allowClear
                        onClear={() => setSelectedValue('')}
                    />
                </Col>
                <Col span={12}>
                    <Search
                        placeholder="ค้นหา Master Data"
                        style={{ marginBottom: '20px' }}
                        onChange={(e) => handleSearch(e.target.value)}
                        enterButton
                        allowClear
                    />
                </Col>
            </Row>
            <Table
                scroll={{ x: 1400 }}
                style={{ marginTop: '16px' }}
                columns={columns}
                dataSource={data !== null ? data : undefined}
                pagination={{
                    current: current,
                    pageSize: 10,
                    total: total,
                    onChange: handlePageChange,
                }}
                locale={
                    data !== null
                        ? {}
                        : {
                              emptyText: (
                                  <Empty
                                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                                      description="ไม่มีข้อมูล"
                                  />
                              ),
                          }
                }
            />
        </>
    )
}

export default MasterDataComponent
