import React from 'react'
import { Form, FormInstance, Input } from 'antd'
import { IBannerForm } from '../../../../model/interface/bannerForm'
import { FieldData } from 'rc-field-form/lib/interface'
import { BannerField } from '../../../../model/enum/banner-field'
import TextArea from 'antd/es/input/TextArea'
import { UploadImg } from '../uploadImg'

interface BannerFormProps {
    form: FormInstance
    onSubmit: (data: IBannerForm) => void
    onCancel?: () => void
    onDelete?: () => void
    onFormChange?: (data: IBannerForm) => void
    data?: IBannerForm | null
    initialValues?: IBannerForm
}

export const BannerForm = (props: BannerFormProps): JSX.Element => {
    const { form, onSubmit, initialValues } = props

    const hookSubmit = (values: IBannerForm) => {

        onSubmit({
            ...values,
        })
    }

    const handleUploadImgDesktopSuccess = (url: string) => {
        form.setFieldsValue({
            [BannerField.IMAGE_URL_DESKTOP]: url,
        })
    }

    const handleUploadImgMobileSuccess = (url: string) => {
        form.setFieldsValue({
            [BannerField.IMAGE_URL_MOBILE]: url,
        })
    }

    const onChangeForm = (_: FieldData[], allFields: IBannerForm) => {
        if (props.onFormChange) {
            props.onFormChange(allFields)
        }
    }

    const handleUploadDesktopDelete = async () => {
        form.setFieldsValue({
            [BannerField.IMAGE_URL_DESKTOP]: '',
        })
    }

    const handleUploadMobileDelete = async () => {
        form.setFieldsValue({
            [BannerField.IMAGE_URL_MOBILE]: '',
        })
    }

    return (
        <>
            <Form
                layout="vertical"
                name="basic"
                requiredMark={false}
                form={form}
                initialValues={initialValues || {}}
                onFinish={hookSubmit}
                onValuesChange={onChangeForm}
                autoComplete="off"
            >
                <Form.Item
                className='custom-form-upload'
                    name={[BannerField.IMAGE_URL_DESKTOP]}
                    label="รูปภาพ Banner สำหรับ Desktop"
                    rules={[{ required: true, message: 'กรุณาเลือกรูปภาพ' }]}
                    style={{ display: 'flex', justifyContent: 'center' }}
                >
                    <UploadImg
                        forMobile={false}
                        onUploadImgSuccess={(url) => {
                            handleUploadImgDesktopSuccess(url)
                        }}
                        onUploadImgDelete={() => {
                            handleUploadDesktopDelete()
                        }}
                        initialValue={form.getFieldValue('imageUrlDesktop')}
                    />
                </Form.Item>
                <Form.Item
                className='custom-form-upload'
                    name={[BannerField.IMAGE_URL_MOBILE]}
                    label="รูปภาพ Banner สำหรับโทรศัพท์ (ไม่บังคับ)"
                    style={{ display: 'flex', justifyContent: 'center' }}
                >
                    <UploadImg
                        forMobile={true}
                        onUploadImgSuccess={(url) => {
                            handleUploadImgMobileSuccess(url)
                        }}
                        onUploadImgDelete={() => {
                            handleUploadMobileDelete()
                        }}
                        initialValue={form.getFieldValue('imageUrlMobile')}
                    />
                </Form.Item>
                <Form.Item
                    name={[BannerField.IMAGE_NAME]}
                    label="ชื่อรูปภาพ Banner"
                    rules={[
                        { required: true, message: 'กรุณากรอกชื่อ Banner' },
                    ]}
                >
                    <Input placeholder="กรุณากรอกชื่อ Banner" />
                </Form.Item>
                <Form.Item
                    name={[BannerField.DEEPLINK]}
                    label="Deep link (ไม่บังคับ)"
                >
                    <Input placeholder="กรุณากรอก Deep link" />
                </Form.Item>
                <Form.Item
                    name={[BannerField.IMAGE_DESCRIPTION]}
                    label="คำอธิบายรูปภาพ Banner"
                    rules={[
                        {
                            required: true,
                            message: 'กรุณากรอกคำอธิบาย Banner',
                        },
                    ]}
                >
                    <TextArea
                        placeholder="กรุณากรอกรายละเอียด Banner"
                        autoSize={{ minRows: 3, maxRows: 10 }}
                    />
                </Form.Item>
            </Form>
        </>
    )
}
