export enum MasterDataField {
    MASTERDATA_URL = 'banner',
    MASTERDATA_TYPEID = 'typeId',
    MASTERDATA_NAME = 'masterDataName',
    MASTERDATA_DESCRIPTION = 'description',
    MASTERDATA_REFCODE = 'refCode',
    MASTERDATA_TYPENAME = 'typeName',
    MASTERDATA_ID = 'id',
    MASTERDATA_CREATE_DATE = 'createDate',
    MASTERDATA_UPDATEDATE_DATE = 'updateDate',
    MASTERDATA_BANNERID = 'bannerId',
    MASTERDATA_LOWERNAME = 'lowercaseMasterDataName',
}

export enum CreateUser {
    EMAIL = 'email',
    ID = 'id',
}
