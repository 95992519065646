import React, { useEffect, useState } from 'react'
import {
    IProductElement,
    ProductApiResponse,
} from '../../../model/interface/product'
import { ProductService } from '../../../service/product.service'
import { Input, Flex } from 'antd'
import { ProductAnotherList } from './list'
import { ProductAnotherSort } from './sort'
import TabCategory from '../components/tabCategory'
import { debounce } from 'lodash'
import { useProductContext } from '../../../context/productContext'

interface ProductAnotherProps {
    isEditingAnother: boolean
    setUpdataCheckedAnother: React.Dispatch<
        React.SetStateAction<{ productId: string }[]>
    >
    onClickCancel: boolean
    isToggleAno: boolean
    isDisabledAno: (disabled: boolean) => void 
}

const ProductAnother = ({
    isEditingAnother,
    setUpdataCheckedAnother,
    onClickCancel,
    isToggleAno,
    isDisabledAno
}: ProductAnotherProps): JSX.Element => {
    const [dataSourceAllProduct, setDataSourceAllProduct] =
        useState<ProductApiResponse>()
    const [tabs, setTabs] = useState<{ index: number; id: string }[]>([])
    const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0)
    const [filterAllProduct, setFilterAllProduct] = useState({
        masterDataItemId: '',
        page: 1,
        pageSize: 10,
        search: '',
        pagination: false,
    })
    const [filteredDataSource, setFilteredDataSource] = useState<
        IProductElement[]
    >([])

    const [checkbox, setCheckBox] = useState<string[]>([])
    const { setCategories } = useProductContext()
    const [checkedCount, setCheckedCount] = useState<number>(0)

    const handleTabChange = (index: number) => {
        setSelectedTabIndex(index)
        setCheckBox([])
        setCheckedCount(0)
    }

    const handleTabsLoaded = (loadedTabs: { index: number; id: string }[]) => {
        setTabs(loadedTabs)
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (tabs.length > 0) {
                    const tabId = tabs[selectedTabIndex].id
                    const product = await ProductService.getMasterDataProduct(
                        tabId,
                        filterAllProduct.page,
                        filterAllProduct.pageSize,
                        filterAllProduct.search,
                        filterAllProduct.pagination
                    )
                    setCategories(tabId)
                    setDataSourceAllProduct(product)
                    isDisabledAno(!product || product.data.length === 0)
                }
            } catch (error) {
                console.error('Error fetching data:', error)
                isDisabledAno(true)
            }
        }

        fetchData()
    }, [filterAllProduct, tabs, selectedTabIndex, setCategories])

    useEffect(() => {
        const fetchPriority = async () => {
            try {
                const priorityData = await ProductService.getAnotherProduct(
                    tabs[selectedTabIndex].id
                )
                const checkboxInitial: IProductElement[] = priorityData.data
                if (!onClickCancel) {
                    setFilteredDataSource(priorityData.data)
                    const newCheckBox: string[] = []
                    priorityData.data.forEach((data) => {
                        if (!checkbox.some((product) => product === data.id)) {
                            newCheckBox.push(data.id)
                        }
                    })
                    setCheckBox(newCheckBox)
                } else {
                    const initialIds = checkboxInitial.map((item) => item.id)
                    setFilteredDataSource(checkboxInitial)

                    const newCheckBox: string[] = []
                    priorityData.data.forEach((data) => {
                        if (!initialIds.includes(data.id)) {
                            newCheckBox.push(data.id)
                        }
                    })
                    const updatedCheckBox = initialIds.concat(newCheckBox)
                    setCheckBox(updatedCheckBox)
                }
            } catch (error) {
                console.error('Error fetching data:', error)
            }
        }
        fetchPriority()
    }, [tabs, selectedTabIndex, isToggleAno])

    useEffect(() => {
        const filteredData = dataSourceAllProduct?.data.filter((product) =>
            checkbox.includes(product.id)
        )

        const sortedData = checkbox
            .map((id) => filteredData?.find((product) => product?.id === id))
            .filter(Boolean)

        const validSortedData = sortedData.filter(
            (product) => product !== undefined
        ) as IProductElement[]

        setFilteredDataSource(validSortedData)

        setUpdataCheckedAnother(
            validSortedData.map((product) => ({ productId: product.id }))
        )
    }, [checkbox, dataSourceAllProduct])

    const { Search } = Input
    const onSearch = debounce((value: string) => {
        setFilterAllProduct((prev) => {
            return { ...prev, search: value }
        })
    }, 500)

    const handleChange = (e: { target: { value: string } }) => {
        const value = e.target.value
        onSearch(value)
    }

    return (
        <>
            <Flex gap={12}>
                <Search
                    placeholder="ค้นหาชื่อสินค้า"
                    onChange={handleChange}
                    enterButton
                />
            </Flex>
            <h3>สินค้าอื่นๆ</h3>
            <TabCategory
                onTabsLoaded={handleTabsLoaded}
                onChange={handleTabChange}
            />
            <ProductAnotherList
                dataSource={dataSourceAllProduct}
                setFilterAllProduct={setFilterAllProduct}
                editable={isEditingAnother}
                checkbox={checkbox}
                setCheckBox={setCheckBox}
                checkedCount={checkedCount}
                setCheckedCount={setCheckedCount}
            />
            <h3 style={{ display: 'flex', justifyContent: 'center' }}>
                ลำดับความสำคัญของสินค้าอื่นๆ
            </h3>
            <ProductAnotherSort
                dataSource={filteredDataSource}
                setDataSource={setFilteredDataSource}
                editable={isEditingAnother}
                setUpdataCheckedAnother={setUpdataCheckedAnother}
            />
        </>
    )
}

export default ProductAnother
