import { PaymentApiResponse } from '../model/interface/payment'
import { axiosInstance } from './axios.api'
import { prefixApi } from './prefix.api'

export class PaymentService {
    private static url = `${prefixApi.payment}`
    private static update = `${prefixApi.updateVerifying}`

    public static async putPayment(
        refId: string,
        status: string
    ): Promise<PaymentApiResponse> {
        const res = await axiosInstance.put(
            `${this.update}/${refId}?status=${status}`
        )
        return res.data
    }

    public static async getPayments(
        page: number,
        pageSize: number,
        refId?: string,
        userId?: string,
        productId?: string,
        status?: string,
        startDate?: string,
        endDate?: string
    ): Promise<PaymentApiResponse> {
        try {
            const res = await axiosInstance.get<PaymentApiResponse>(
                `${this.url}s`,
                {
                    params: {
                        page,
                        pageSize,
                        refId,
                        userId,
                        productId,
                        status,
                        startDate,
                        endDate,
                    },
                }
            )
            return res.data
        } catch (error) {
            console.error('Error fetching payments:', error)
            throw error
        }
    }
}
