import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { UserProfile, detoken, useAuth } from '../../context/Profile.context'
import { RoutePath } from '../../model/enum/route-path'
import dayjs from 'dayjs'

const AuthGuard: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const auth = useAuth()
    const location = useLocation()
    const navigate = useNavigate()

    useEffect(() => {
        const isPublicPath = (path: string): boolean => {
            const publicPaths = [
                RoutePath.AUTH,
                RoutePath.LOGOUT,
                RoutePath.FORBIDDEN,
            ].map(String)
            return publicPaths.includes(path)
        }

        const redirectToForbiddenPage = (): void => {
            window.localStorage.removeItem('access_token')
            auth.setProfile(null)
            navigate(RoutePath.FORBIDDEN)
        }
        const checkPermission = (): void => {
            const pathName = location.pathname.split('/')
            const currentPath = pathName[1] as RoutePath

            if (!auth.token) {
                if (!isPublicPath(currentPath)) {
                    navigate(RoutePath.AUTH)
                }
                return
            }

            if (!isPublicPath(currentPath)) {
                const userProfile: UserProfile = detoken(auth.token)
                if (userProfile.role !== 'admin') {
                    navigate(RoutePath.AUTH)
                    return
                }
            }

            if (auth.profile) {
                if (dayjs().isAfter(dayjs.unix(auth.profile.exp))) {
                    redirectToForbiddenPage()
                    return
                }
            }
        }

        checkPermission()
    }, [location.pathname, auth.token, navigate, auth.profile?.role])

    return <>{children}</>
}

export default AuthGuard
