export enum AdminField {
    ID = 'id',
    USER_ID = 'userId',
    DISPLAY_NAME = 'displayName',
    EMAIL = 'email',
    PASSWORD = 'password',
    CONFIRM_PASSWORD = 'confirmPassword',
    FIRST_NAME = 'firstName',
    LAST_NAME = 'lastName',
    OLD_PASSWORD = 'oldPassword',
}
