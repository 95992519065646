import React from 'react';
import {
    Button,
    Card,
    Flex,
    Form,
    FormInstance,
    FormListFieldData,
    FormListOperation,
    Input,
} from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import ChoiceItemForm from '../choiceItemForm';
import InputDetail from '../../../../../../../components/inputDetail';

interface ProductItemformProps {
    form: FormInstance;
    fields: FormListFieldData[];
    operations: FormListOperation;
}

const ProductItemform: React.FC<ProductItemformProps> = ({
    form,
    fields,
    operations,
}) => {
    return (
        <Flex vertical gap={16}>
            {fields.map((field, index) => (
                <Card
                    key={field.key}
                    title={`ส่วนย่อยที่ ${index + 1}`}
                    size="small"
                    extra={
                        fields.length > 1 && (
                            <CloseOutlined
                                onClick={() => {
                                    operations.remove(field.name);
                                }}
                            />
                        )
                    }
                >
                    <Form.Item
                        name={[field.name, 'title']}
                        label="ชื่อส่วนย่อย"
                        rules={[
                            {
                                required: true,
                                message: 'กรุณากรอกชื่อของส่วนย่อย',
                            },
                        ]}
                    >
                        <Input placeholder="กรุณากรอกชื่อของส่วนย่อย" />
                    </Form.Item>
                    <Form.Item
                        name={[field.name, 'description']}
                        label="รายละเอียดส่วนย่อย"
                        rules={[
                            {
                                required: true,
                                message: 'กรุณากรอกรายละเอียดของส่วนย่อย',
                            },
                        ]}
                    >
                        <InputDetail
                            form={form}
                            name={['productItem', field.name, 'description']}
                            placeholder="กรุณากรอกรายละเอียดของส่วนย่อย"
                        />
                    </Form.Item>

                    <Form.List
                        name={[field.name, 'choiceItem']}
                        initialValue={[{}]}
                    >
                        {(subFields, subOpt) => (
                            <ChoiceItemForm
                                form={form}
                                field={field}
                                subFields={subFields}
                                subOpt={subOpt}
                            />
                        )}
                    </Form.List>
                </Card>
            ))}
            <Button type="dashed" onClick={() => operations.add()} block>
                + สร้างส่วนย่อยใหม่
            </Button>
        </Flex>
    );
};

export default ProductItemform;
