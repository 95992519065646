import React from 'react'
import { MenuOutlined } from '@ant-design/icons'
import { DndContext, DragEndEvent } from '@dnd-kit/core'
import {
    SortableContext,
    arrayMove,
    useSortable,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import { Table } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { Image } from 'antd'
import { restrictToVerticalAxis } from '@dnd-kit/modifiers'
import { IProductElement } from '../../../../model/interface/product'
import { useProductContext } from '../../../../context/productContext'

interface ProductListProps {
    dataSource: IProductElement[]
    setDataSource: React.Dispatch<React.SetStateAction<IProductElement[]>>
    editable: boolean
    setUpdataCheckedAnother: React.Dispatch<React.SetStateAction<{ productId: string }[]>>
}

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
    'data-row-key': string
}

const Row = ({ children, ...props }: RowProps) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        setActivatorNodeRef,
        transform,
        transition,
        isDragging,
    } = useSortable({
        id: props['data-row-key'],
    })

    const style: React.CSSProperties = {
        ...props.style,
        transform: transform ? `scaleY(1) ${transform}` : undefined,
        transition,
        ...(isDragging ? { position: 'relative', zIndex: 100 } : {}),
    }

    return (
        <tr {...props} ref={setNodeRef} style={style} {...attributes}>
            {React.Children.map(children, (child) => {
                if ((child as React.ReactElement).key === 'sort') {
                    return React.cloneElement(child as React.ReactElement, {
                        children: (
                            <MenuOutlined
                                ref={setActivatorNodeRef}
                                style={{ touchAction: 'none', cursor: 'move' }}
                                {...listeners}
                            />
                        ),
                    })
                }
                return child
            })}
        </tr>
    )
}

export const ProductAnotherSort = (props: ProductListProps): JSX.Element => {
    const { editable, dataSource, setDataSource, setUpdataCheckedAnother } = props;
    const { categories } = useProductContext()

    const onDragEnd = ({ active, over }: DragEndEvent) => {
        if (active.id !== over?.id) {
            const newDataSource = arrayMove(
                dataSource,
                dataSource.findIndex((i) => i.id === active.id),
                dataSource.findIndex((i) => i.id === over?.id)
            )

            setDataSource(newDataSource)
            setUpdataCheckedAnother(newDataSource.map((product) => ({ categories, productId: product.id })))

        }
    }


    const columnsSort: ColumnsType<IProductElement> = [
        {
            key: 'sort',
            width: '28px',
        },
        {
            title: 'ลำดับ',
            dataIndex: 'number',
            width: '80px',
            render: (_, record, index) => <span>{index + 1}</span>,
        },

        {
            title: 'รูปภาพของสินค้า	',
            dataIndex: 'bannerImg',
            width: '200px',
            render: (_, record) => (
                <Image
                    width={200}
                    style={{ aspectRatio: '16/9', objectFit: 'cover' }}
                    src={record.banner}
                />
            ),
        },
        {
            title: 'ชื่อสินค้า',
            dataIndex: 'title',
            width: '200px',
        },
        {
            title: 'ราคา',
            dataIndex: 'number',
            width: '100px',
            render: (_, record) => (
                <div style={{ lineBreak: 'auto', width: '100px' }}>
                    {record.price.toLocaleString()}
                </div>
            ),
        },
        {
            title: 'หมวดหมู่',
            dataIndex: 'category',
            width: '150px',
            render: (_, record) => (
                <div style={{ lineBreak: 'auto', width: '100px' }}>
                    {record.category.name}
                </div>
            ),
        },
        {
            title: 'เผยแพร่โดย',
            dataIndex: 'auther',
            width: '130px',
            render: (_, record) => (
                <div style={{ lineBreak: 'auto', width: '130px' }}>
                    {record.createBy.email}
                </div>
            ),
        },
    ]

    return (
        <>
            <DndContext
                modifiers={[restrictToVerticalAxis]}
                onDragEnd={onDragEnd}
            >
                <SortableContext
                    disabled={!editable}
                    items={dataSource.map((i) => i.id)}
                    strategy={verticalListSortingStrategy}
                >
                    <Table
                        components={{
                            body: {
                                row: Row,
                            },
                        }}
                        rowKey="id"
                        columns={columnsSort}
                        dataSource={dataSource}
                        pagination={false}
                    />
                </SortableContext>
            </DndContext>
        </>
    );
}