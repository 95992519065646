import { IBannerElement } from '../model/interface/bannerElement'
import { IBannerForm } from '../model/interface/bannerForm'
import { IBannerListResponse } from '../model/response/banner.response'
import { axiosInstance } from './axios.api'
import { prefixApi } from './prefix.api'

export class BannerService {
    private static url = `${prefixApi.banner}`

    public static async getBanner(): Promise<IBannerListResponse> {
        const res = await axiosInstance.get<IBannerListResponse>(`${this.url}s`)
        return res.data
    }

    public static async getBannerById(id: string): Promise<IBannerElement> {
        const res = await axiosInstance.get<IBannerElement>(`${this.url}/${id}`)
        return res.data
    }

    public static async createBanner(data: IBannerForm): Promise<IBannerForm> {
        const res = await axiosInstance.post<IBannerForm>(this.url, data)
        return res.data
    }

    public static async updateBanner(
        id: string,
        data: IBannerForm
    ): Promise<IBannerForm> {
        const res = await axiosInstance.put<IBannerForm>(
            `${this.url}/${id}`,
            data
        )
        return res.data
    }

    public static async deleteBanner(id: string): Promise<IBannerForm> {
        const res = await axiosInstance.delete(`${this.url}/${id}`)
        return res.data
    }

    public static async updateBannerPriority(
        data: string[]
    ): Promise<IBannerElement[]> {
        const res = await axiosInstance.put<IBannerElement[]>(
            `${this.url}/priority`,
            data
        )
        return res.data
    }
}
