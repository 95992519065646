import React, { useEffect, useState } from 'react'
import PageLayout from '../../../components/page-layout'
import { Button, Form, Space } from 'antd'
import { BannerForm } from '../components/bannerForm'
import { IBannerForm } from '../../../model/interface/bannerForm'
import { useNavigate, useParams } from 'react-router'
import { BannerService } from '../../../service/banner.service'
import { notificationMessage } from '../../../components/notification'
import ConfirmationModal, {
    ActionType,
} from '../../../components/confirmationModal'

export const BannerDetail: React.FC = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [bannerData, setBannerData] = useState<IBannerForm>()
    const navigate = useNavigate()
    const [form] = Form.useForm()
    const [disabled, setDisabled] = useState(false)
    const { id } = useParams<{ id: string }>()

    const onClickCancel = () => navigate('/banner')

    const onSubmitForm = async (formData: IBannerForm) => {
        // setDisabled(true)
        showEditConfirm(formData)
    }

    useEffect(() => {
        if (id) {
            const fetchData = async () => {
                try {
                    const bannerData = await BannerService.getBannerById(id)
                    form.setFieldsValue(bannerData)
                    setBannerData(bannerData)
                } catch (error) {
                    console.error('Error fetching banner data:', error)
                }
            }

            fetchData()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleUpdate = async (req: IBannerForm) => {
        try {
            setDisabled(true)
            if (id) {
                await BannerService.updateBanner(id, req).then(() => {
                    notificationMessage({
                        type: 'success',
                        message: 'แก้ไข Banner สำเร็จ',
                    })
                })
            }
        } catch (error) {
            notificationMessage({
                type: 'error',
                message: 'แก้ไข Banner ไม่สำเร็จ',
            })
            console.error('Error deleting banner:', error)
        } finally {
            navigate(`/banner`)
        }
    }

    const showCancelConfirm = () => {
        ConfirmationModal({
            mode: 'cancelUpdate' as ActionType,
            title: 'Banner',
            onOk: () => navigate('/banner'),
        })
    }

    const showEditConfirm = (req: IBannerForm) => {
        ConfirmationModal({
            mode: 'update' as ActionType,
            title: 'Banner',
            onOk: () => handleUpdate(req),
        })
    }
    return (
        <>
            <PageLayout
                title="แก้ไข Banner"
                action={
                    <Space>
                        <Button
                            type="default"
                            onClick={() => showCancelConfirm()}
                            disabled={disabled}
                        >
                            ยกเลิก
                        </Button>
                        <Button
                            type="primary"
                            disabled={disabled}
                            onClick={() => form.submit()}
                        >
                            บันทึก
                        </Button>
                    </Space>
                }
            >
                <div>
                    <BannerForm
                        form={form}
                        onSubmit={onSubmitForm}
                        initialValues={bannerData}
                        onCancel={onClickCancel}
                    />
                </div>
            </PageLayout>
        </>
    )
}
