import React from 'react'
import {
    UploadProps,
    message,
    UploadFile,
    Button,
    Upload,
    FormListFieldData,
    FormInstance,
} from 'antd'
import { UploadChangeParam } from 'antd/lib/upload'
import { UploadOutlined } from '@ant-design/icons'

interface InputUploadFileProps {
    form: FormInstance
    field: FormListFieldData
    subField: FormListFieldData
    initialValues?: string
}

const InputUploadFile: React.FC<InputUploadFileProps> = ({
    form,
    field,
    subField,
    initialValues,
}) => {
    const [fileList, setFileList] = React.useState<UploadFile[]>([])
    React.useEffect(() => {
        if (initialValues) {
            const fileName = initialValues.split('/').pop()?.split('_').shift()
            setFileList([
                {
                    uid: subField.key.toString(),
                    name: fileName || '',
                    status: 'done',
                    url: initialValues,
                },
            ])
        } else {
            setFileList([])
        }
    }, [subField, initialValues])

    const uploadProps: UploadProps = {
        name: 'file',
        action: `${process.env.REACT_APP_API_URL_UAT}/api/admin/upload-file`,
        headers: {
            Authorization: `Bearer ${window.localStorage.getItem(
                'access_token'
            )}`,
        },
        accept: 'application/pdf',
        maxCount: 1,
        fileList: fileList,
        onChange: (info: UploadChangeParam) => {
            const { status, name, response } = info?.file
            if (status === 'done' && response) {
                message.success(`${name} อัปโหลดไฟล์เสร็จสิ้น`)
                form.setFieldValue(
                    [
                        'productItem',
                        field.name,
                        'choiceItem',
                        subField.key,
                        'file',
                    ],
                    response?.url
                )
            } else if (status === 'error') {
                message.error(`${name} อัปโหลดไฟล์ล้มเหลว`)
            }
            setFileList(info.fileList)
        },
        onPreview: async (file: UploadFile) => {
            window.open(file.url || file.response.url, '_blank')
        },
        onRemove: () => {
            form.setFieldValue(
                ['productItem', field.name, 'choiceItem', subField.key, 'file'],
                undefined
            )
        },
    }
    const isError =
        form.getFieldError([
            'productItem',
            field.name,
            'choiceItem',
            subField.key,
            'file',
        ]).length !== 0
    return (
        <Upload {...uploadProps}>
            <Button danger={isError} icon={<UploadOutlined />}>
                อัปโหลดไฟล์
            </Button>
        </Upload>
    )
}

export default InputUploadFile
