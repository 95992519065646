import React from 'react'
import {
    Navigate,
    Outlet,
    Route,
    RouterProvider,
    createBrowserRouter,
    createRoutesFromElements,
} from 'react-router-dom'
import { RoutePath } from './model/enum/route-path'
import LoginPage from './pages/auth/login'
import HomePage from './pages/home'
import NavigationLayout from './layout/Navigation.layout'
import BannerPage from './pages/banner'
import { CreateBanner } from './pages/banner/create'
import { BannerDetail } from './pages/banner/edit'
import AdminLoginFail from './pages/auth/login-failed'
import AuthGuard from './components/auth-guard'
import { AuthProvider } from './context/Profile.context'
import BlogPage from './pages/blog/page'
import { CreateBlog } from './pages/blog/pages/create/page'
import { EditBlog } from './pages/blog/pages/edit/page'
import MasterDataPage from './pages/master_data'
import { CreateMaster } from './pages/master_data/create'
import { EditMaster } from './pages/master_data/edit'
import { ProductProvider } from './context/productContext'
import ProductPage from './pages/product'
import { CreateProduct } from './pages/product/allProduct/create'
import { EditProduct } from './pages/product/allProduct/edit'
import ContentPage from './pages/content'
import CreateContentPage from './pages/content/create'
import EditContentPage from './pages/content/edit'
import AdminPage from './pages/admin'
import { CreateAdmin } from './pages/admin/create'
import { AdminDetail } from './pages/admin/edit'
import PaymentPage from './pages/payment'

const ApplicationRoute = (): JSX.Element => {
    const router = createBrowserRouter(
        createRoutesFromElements(
            <Route
                element={
                    <AuthProvider>
                        <AuthGuard>
                            <Outlet />
                        </AuthGuard>
                    </AuthProvider>
                }
            >
                <Route
                    path="*"
                    element={<Navigate to={`/${RoutePath.AUTH}`} replace />}
                />
                <Route path={RoutePath.AUTH} element={<Outlet />}>
                    <Route path="" element={<LoginPage />} />
                </Route>
                <Route
                    path={RoutePath.FORBIDDEN}
                    element={<AdminLoginFail />}
                />
                <Route
                    path={RoutePath.HEALTH}
                    element={<>It's All Good, API is Working :)</>}
                />
                <Route path="/" element={<NavigationLayout />}>
                    <Route
                        index
                        element={<Navigate to={`/${RoutePath.HOME}`} />}
                    />
                    <Route path={RoutePath.HOME} element={<Outlet />}>
                        <Route id="home" path="" element={<HomePage />} />
                    </Route>
                    <Route path={RoutePath.BANNER} element={<Outlet />}>
                        <Route id="banner" path="" element={<BannerPage />} />
                        <Route
                            id="banner-create"
                            path="create"
                            element={<CreateBanner />}
                        />
                        <Route
                            id="banner-detail"
                            path="edit/:id"
                            element={<BannerDetail />}
                        />
                    </Route>
                    <Route path={RoutePath.ADMIN} element={<Outlet />}>
                        <Route id="admin" path="" element={<AdminPage />} />
                        <Route
                            id="admin-create"
                            path="create"
                            element={<CreateAdmin />}
                        />
                        <Route
                            id="admin-detail"
                            path="edit/:id"
                            element={<AdminDetail />}
                        />
                    </Route>
                    <Route path={RoutePath.PRODUCT} element={<Outlet />}>
                        <Route
                            id="product"
                            path=""
                            element={
                                <ProductProvider>
                                    <ProductPage />
                                </ProductProvider>
                            }
                        />
                        <Route
                            id="product-create"
                            path="create"
                            element={<CreateProduct />}
                        />
                        <Route
                            id="product-detail"
                            path="edit/:id"
                            element={<EditProduct />}
                        />
                    </Route>
                    <Route path={RoutePath.MASTER} element={<Outlet />}>
                        <Route
                            id="master"
                            path=""
                            element={<MasterDataPage />}
                        />
                        <Route
                            id="master-create"
                            path="create"
                            element={<CreateMaster />}
                        />
                        <Route
                            id="master-edit"
                            path="edit/:editID"
                            element={<EditMaster />}
                        />
                    </Route>
                    <Route
                        path={RoutePath.FORBIDDEN}
                        element={<AdminLoginFail />}
                    />
                    <Route path={RoutePath.CONTENT} element={<Outlet />}>
                        <Route id="content" path="" element={<ContentPage />} />
                        <Route
                            id="content-create"
                            path="create"
                            element={<CreateContentPage />}
                        />
                        <Route
                            id="content-edit"
                            path="edit/:id"
                            element={<EditContentPage />}
                        />
                    </Route>
                    <Route path={RoutePath.BLOG} element={<Outlet />}>
                        <Route id="blog" path="" element={<BlogPage />} />
                        <Route
                            id="blog-create"
                            path="create"
                            element={<CreateBlog />}
                        />

                        <Route
                            id="blog-edit"
                            path="edit/:id"
                            element={<EditBlog />}
                        />
                    </Route>
                    <Route path={RoutePath.PAYMENT} element={<Outlet />}>
                        <Route id="payment" path="" element={<PaymentPage />} />
                    </Route>
                   
                </Route>
            </Route>
        )
    )

    return <RouterProvider router={router} />
}

export default ApplicationRoute
