import React from 'react'
import PageLayout from '../../../../components/page-layout'
import { Button, Space } from 'antd'
import { BlogForm } from '../../components/blogForm'
import useBlogForm from '../../components/blogForm/useBlogForm'

export const EditBlog: React.FC = () => {
    const { confirmCancelBlog, disabled, isCreate } = useBlogForm()

    return (
        <>
            <PageLayout
                title="ระบบจัดการบทความ"
                action={
                    <Space>
                        <Button
                            type="default"
                            onClick={() => confirmCancelBlog(isCreate)}
                            disabled={disabled}
                        >
                            ยกเลิก
                        </Button>
                        <Button
                            type="primary"
                            htmlType="submit"
                            form="blog-form"
                            disabled={disabled}
                        >
                            บันทึก
                        </Button>
                    </Space>
                }
            >
                <BlogForm />
            </PageLayout>
        </>
    )
}
