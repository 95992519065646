import React, { useState } from 'react'
import PageLayout from '../../components/page-layout'
import { Button, Space } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { RoutePath } from '../../model/enum/route-path'
import { useNavigate } from 'react-router'
import Tab from './components/tab'
import { ProductService } from '../../service/product.service'
import { useProductContext } from '../../context/productContext'
import { notificationMessage } from '../../components/notification'
import ConfirmationModal, {
    ActionType,
} from '../../components/confirmationModal'

const ProductPage = (): JSX.Element => {
    const navigate = useNavigate()
    const [selectedKey, setSelectedKey] = useState<string>('1')
    const [isEditing, setIsEditing] = useState<boolean>(false)
    const [isEditingAnother, setIsEditingAnother] = useState<boolean>(false)
    const [updateChecked, setUpdataChecked] = useState<string[]>([])
    const [updataCheckedAnother, setUpdataCheckedAnother] = useState<
        { productId: string }[]
    >([])
    const { categories } = useProductContext()
    const [onClickCancel, setOnClickCancel] = useState<boolean>(false)
    const [onClickCancelRec, setOnClickCancelRec] = useState<boolean>(false)
    const [isToggleRec, setIsToggleRec] = useState<boolean>(false)
    const [isToggleAno, setIsToggleAno] = useState<boolean>(false)
    const [isDisableRec, setIsDisableRec] = useState<boolean>(false)
    const [isDisableAno, setIsDisableAno] = useState<boolean>(false)

    const handleTabChange = (key: string) => {
        setSelectedKey(key)
        setIsEditing(false)
        setIsEditingAnother(false)
    }

    const handleEditClick = () => {
        if (selectedKey === '2') {
            setIsEditing(true)
        } else if (selectedKey === '3') {
            setIsEditingAnother(true)
        }
    }

    const handleCancelEditAnother = async () => {
        setIsEditing(false)
        setIsEditingAnother(false)
        setOnClickCancel(true)
        setIsToggleAno((prev) => !prev)
        notificationMessage({
            type: 'success',
            message: 'ยกเลิกสำเร็จ',
        })
    }

    const showCancelConfirmAnother = () => {
        ConfirmationModal({
            mode: 'cancelUpdate' as ActionType,
            title: 'สินค้าอื่นๆ',
            onOk: () => handleCancelEditAnother(),
        })
    }

    const handleCancelRec = async () => {
        setIsEditing(false)
        setIsEditingAnother(false)
        setOnClickCancelRec(true)
        setIsToggleRec((prev) => !prev)
        notificationMessage({
            type: 'success',
            message: 'ยกเลิกสำเร็จ',
        })
    }

    const showCancelConfirmRec = () => {
        ConfirmationModal({
            mode: 'cancelUpdate' as ActionType,
            title: 'สินค้าแนะนำ',
            onOk: () => handleCancelRec(),
        })
    }

    const handleSaveRecomment = async () => {
        try {
            await ProductService.postProductRecomment({ data: updateChecked })
            setIsEditing(false)
            setIsEditingAnother(false)
            notificationMessage({
                type: 'success',
                message: 'แก้ไขข้อมูลสำเร็จ',
            })
        } catch (error) {
            notificationMessage({
                type: 'error',
                message: 'แก้ไขข้อมูลไม่สำเร็จ',
            })
            console.error('Error saving data:', error)
        }
    }

    const showCreateConfirmRecomment = () => {
        ConfirmationModal({
            mode: 'update' as ActionType,
            title: 'สินค้าแนะนำ',
            onOk: () => handleSaveRecomment(),
        })
    }

    const handleSaveAnother = async () => {
        try {
            const ids = updataCheckedAnother.map((item) => item.productId)
            const payload = {
                categoryId: categories,
                data: ids,
            }

            await ProductService.postAnotherProduct(payload)

            setIsEditing(false)
            setIsEditingAnother(false)
            notificationMessage({
                type: 'success',
                message: 'แก้ไขข้อมูลสำเร็จ',
            })
        } catch (error) {
            notificationMessage({
                type: 'error',
                message: 'แก้ไขข้อมูลไม่สำเร็จ',
            })
            console.error('Error saving data:', error)
        }
    }

    const showCreateConfirmAnother = () => {
        ConfirmationModal({
            mode: 'update' as ActionType,
            title: 'สินค้าอื่นๆ',
            onOk: () => handleSaveAnother(),
        })
    }

    return (
        <PageLayout
            title="ระบบจัดการสินค้า"
            action={
                <Space>
                    {selectedKey === '1' && (
                        <Button
                            onClick={() =>
                                navigate(`/${RoutePath.PRODUCT}/create`)
                            }
                            type="primary"
                        >
                            <PlusOutlined />
                            สร้างสินค้า
                        </Button>
                    )}
                    {selectedKey === '2' && !isEditing && (
                        <Button disabled={isDisableRec} onClick={handleEditClick} type="primary">
                            แก้ไขสินค้าแนะนำ
                        </Button>
                    )}
                    {isEditing && (
                        <>
                            <Button onClick={showCancelConfirmRec}>
                                ยกเลิก
                            </Button>
                            <Button
                                onClick={showCreateConfirmRecomment}
                                type="primary"
                            >
                                บันทึก
                            </Button>
                        </>
                    )}
                    {selectedKey === '3' && !isEditingAnother && (
                        <Button disabled={isDisableAno}  onClick={handleEditClick} type="primary">
                            แก้ไขสินค้าอื่นๆ
                        </Button>
                    )}
                    {isEditingAnother && (
                        <>
                            <Button onClick={showCancelConfirmAnother}>
                                ยกเลิก
                            </Button>
                            <Button
                                onClick={showCreateConfirmAnother}
                                type="primary"
                            >
                                บันทึก
                            </Button>
                        </>
                    )}
                </Space>
            }
        >
            <Tab
                onChange={handleTabChange}
                isEditing={isEditing}
                isEditingAnother={isEditingAnother}
                setUpdataChecked={setUpdataChecked}
                setUpdataCheckedAnother={setUpdataCheckedAnother}
                onClickCancel={onClickCancel}
                onClickCancelRec={onClickCancelRec}
                isToggleRec={isToggleRec}
                isToggleAno={isToggleAno}
                isDisabledRec={setIsDisableRec} 
                isDisabledAno={setIsDisableAno}
            />
        </PageLayout>
    )
}

export default ProductPage
