import React from 'react'
import { Modal } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { LegacyButtonType } from 'antd/es/button/button'

const { confirm } = Modal

export type ActionType =
    | 'create'
    | 'update'
    | 'delete'
    | 'cancelCreate'
    | 'cancelUpdate'

interface ConfirmationModalProps {
    mode: ActionType
    title: string
    onOk: () => void
    okType?: LegacyButtonType
    okText?: string
    cancelText?: string
    icon?: React.ReactNode
    onCancel?: () => void
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
    mode,
    title,
    onOk,
    okType = 'default',
    okText = 'ตกลง',
    cancelText = 'ยกเลิก',
    icon,
    onCancel,
}) => {
    let confirmTitle: string
    switch (mode) {
        case 'create':
            confirmTitle = `กรุณายืนยันการสร้าง ${title}?`
            break
        case 'update':
            confirmTitle = `กรุณายืนยันการแก้ไข ${title}?`
            break
        case 'delete':
            confirmTitle = `กรุณายืนยันการลบ ${title}?`
            break
        case 'cancelCreate':
            confirmTitle = `กรุณายืนยันยกเลิกการสร้าง ${title}?`
            break
        case 'cancelUpdate':
            confirmTitle = `กรุณายืนยันยกเลิกการแก้ไข ${title}?`
            break
        default:
            confirmTitle = ''
    }

    confirm({
        title: confirmTitle,
        icon: icon || <ExclamationCircleOutlined />,
        okText,
        okType,
        cancelText,
        onOk,
        onCancel,
    })

    return null
}

export default ConfirmationModal
