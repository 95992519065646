import React, { createContext, useContext, useState } from 'react';

interface ProductContextType {
    categories: string; 
    setCategories: React.Dispatch<React.SetStateAction<string>>;
}



const ProductContext = createContext<ProductContextType | undefined>(undefined);

export const useProductContext = (): ProductContextType => {
    const context = useContext(ProductContext);
    if (!context) {
        throw new Error('useProductContext must be used within a ProductProvider');
    }
    return context;
};


export const ProductProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [categories, setCategories] = useState<string>('');

    const contextValue: ProductContextType = {
        categories,
        setCategories,
    };

    return (
        <ProductContext.Provider value={contextValue}>
            {children}
        </ProductContext.Provider>
    );
};
