import { prefixApi } from './prefix.api'
import { axiosInstance } from './axios.api'
import { Content, ContentApiResponse } from '../model/interface/content'
import { ContentRequest } from '../model/interface/request/contentRequest'

export class ContentService {
    private static url = `${prefixApi.content}`

    public static async getAllContent(): Promise<ContentApiResponse> {
        const res = await axiosInstance.get<ContentApiResponse>(`${this.url}s`)
        return res.data
    }

    public static async getById(id: string): Promise<Content> {
        const res = await axiosInstance.get<Content>(`${this.url}/${id}`)
        return res.data
    }

    public static async createContent(
        body: ContentRequest
    ): Promise<ContentApiResponse> {
        const res = await axiosInstance.post<ContentApiResponse>(
            `${this.url}`,
            body
        )
        return res.data
    }

    public static async editContent(
        id: string,
        body: ContentRequest
    ): Promise<ContentApiResponse> {
        const res = await axiosInstance.put<ContentApiResponse>(
            `${this.url}/${id}`,
            body
        )
        return res.data
    }

    public static async deletContent(id: string): Promise<ContentApiResponse> {
        const res = await axiosInstance.delete<ContentApiResponse>(
            `${this.url}/${id}`
        )
        return res.data
    }
}
