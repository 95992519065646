import React, { createContext, useContext, ReactNode, useState } from "react";
import { AuthContextProps } from "../model/interface/contextType";

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<string>("someUser");
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);

  const login = (email: string, password: string) => {

    if (email !== "") {
      setUser(email);
      setIsLoggedIn(true);
    }
    if (password !== "") {
      console.log(password)
    }
  };

  const logout = () => {
    setUser("");
    setIsLoggedIn(false);
  };

  return <AuthContext.Provider value={{ isLoggedIn ,user, login, logout }}>{children}</AuthContext.Provider>;
};

export const useAuth = (): AuthContextProps => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
