import React from 'react'

interface UserProfileProps {
    collapsed: boolean
}

const LogoComponent: React.FC<UserProfileProps> = ({ collapsed }) => {
    return (
        <div>
            {collapsed ? (
                <h1 style={{ textAlign: 'center' }}>CB</h1>
            ) : (
                <h1 style={{ textAlign: 'center' }}>Coquet. Beauty</h1>
            )}
        </div>
    )
}

export default LogoComponent
