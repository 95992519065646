import React, { useEffect, useState } from 'react'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { Button, Table, Input, Spin, message } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { Image } from 'antd'
import { Blog } from '../../../model/interface/blog'
import { BlogService } from '../../../service/blog.service'
import { useNavigate } from 'react-router'
import debounce from 'lodash/debounce'
import ConfirmationModal, {
    ActionType,
} from '../../../components/confirmationModal'

const { Search } = Input

export const BlogList = (): JSX.Element => {
    const navigate = useNavigate()

    const [blogs, setBlogs] = useState<Blog[]>([])
    const [loading, setLoading] = useState(false)
    const [total, setTotal] = useState(0)
    const [current, setCurrent] = useState(1)
    const [searchValue, setSearchValue] = useState<string>('')

    const columns: ColumnsType<Blog> = [
        {
            title: 'รูป Banner ของบทความ',
            width: '200px',
            key: 'image',
            align: 'center',
            render: (_, record) => (
                <Image
                    src={record.image}
                    style={{ aspectRatio: '16 / 9', objectFit: 'cover' }}
                />
            ),
        },
        {
            title: 'ชื่อบทความ',
            align: 'left',
            key: 'title',
            dataIndex: 'title',
        },
        {
            title: '',
            dataIndex: 'action',
            key: 'action',
            width: '250px',
            render: (_: string, blog) => (
                <div>
                    <Button
                        type="dashed"
                        style={{ marginRight: '10px' }}
                        onClick={() => onEdit(blog.id)}
                    >
                        <EditOutlined style={{ fontSize: '18px' }} />
                        แก้ไข
                    </Button>
                    <Button
                        danger
                        type="dashed"
                        onClick={() => showDeleteConfirm(blog.id)}
                    >
                        <DeleteOutlined style={{ fontSize: '18px' }} />
                        ลบ
                    </Button>
                </div>
            ),
        },
    ]

    useEffect(() => {
        fetchBlogs()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [current, searchValue])

    const fetchBlogs = async () => {
        try {
            setLoading(true)
            const res = await BlogService.getAllBlog(current, 10, searchValue)
            setBlogs(res.data)
            setTotal(res.total)
        } catch (error) {
            console.error('Error fetching data:', error)
        } finally {
            setLoading(false)
        }
    }

    const handlePageChange = (page: number) => {
        setCurrent(page)
    }

    const handleSearch = debounce((value: string) => {
        setSearchValue(value)
    }, 300)

    const handleDelete = async (id: string) => {
        try {
            await BlogService.deleteBlog(id)
            message.success(`ลบบทความเสร็จสิ้น`)
            fetchBlogs()
        } catch (error) {
            console.error('Error deleting blog:', error)
            message.error(`ลบบทความล้มเหลว`)
        }
    }

    const showDeleteConfirm = (id: string) => {
        ConfirmationModal({
            mode: 'delete' as ActionType,
            title: 'บทความ',
            onOk: () => handleDelete(id),
            okType: 'danger',
        })
    }

    const onEdit = (id: string) => navigate(`/blog/edit/${id}`)

    return (
        <>
            <Search
                placeholder="ค้นหาชื่อบทความ"
                style={{ marginBottom: '20px' }}
                onChange={(e) => handleSearch(e.target.value)}
                enterButton
            />
            <Spin spinning={loading}>
                <Table
                    columns={columns}
                    dataSource={blogs}
                    rowKey="id"
                    pagination={{
                        current: current,
                        pageSize: 10,
                        total: total,
                        onChange: handlePageChange,
                    }}
                />
            </Spin>
        </>
    )
}
