import React, { createContext, useContext, useEffect, useState } from 'react'
import { AuthService } from '../service/auth.service'
import { jwtDecode } from 'jwt-decode'
import { useNavigate } from 'react-router'
import { AxiosError } from 'axios'
import { Modal } from 'antd'
import { notificationMessage } from '../components/notification'
import { ExclamationCircleFilled } from '@ant-design/icons'

export interface UserProfile {
    email: string
    password: string
    userId: string
    lineUserId: string
    displayName: string
    createDate: string
    updateDate: string
    role: string
    exp: number
}

interface ILogin {
    email: string
    password: string
}

interface AuthContextType {
    loginAction: (data: ILogin) => Promise<void>
    logOut: () => void
    profile: UserProfile | null
    token: string
    setProfile: React.Dispatch<React.SetStateAction<UserProfile | null>>
}

const AuthContext = createContext<AuthContextType | undefined>(undefined)

export const useAuth = (): AuthContextType => {
    const context = useContext(AuthContext)
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider')
    }
    return context
}

export const detoken = (token: string): UserProfile => {
    const decodedToken: UserProfile = jwtDecode(token)
    const userProfile: UserProfile = {
        email: decodedToken.email,
        password: decodedToken.password,
        userId: decodedToken.userId,
        lineUserId: decodedToken.lineUserId,
        displayName: decodedToken.displayName,
        createDate: decodedToken.createDate,
        updateDate: decodedToken.updateDate,
        role: decodedToken.role,
        exp: decodedToken.exp,
    }
    return userProfile
}

export const AuthProvider: React.FC<{ children?: React.ReactNode }> = ({
    children,
}) => {
    const [profile, setProfile] = useState<UserProfile | null>(null)
    const [token, setToken] = useState(
        localStorage.getItem('access_token') || ''
    )
    const navigate = useNavigate()
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [modalContent, setModalContent] = useState<string>('')
    const [modal, contextHolder] = Modal.useModal()

    const handleOk = () => {
        setIsModalOpen(false)
    }

    const handleCancel = () => {
        setIsModalOpen(false)
    }

    useEffect(() => {
        const fetchUserProfile = async () => {
            try {
                if (token) {
                    const userProfile = detoken(token)
                    setProfile(userProfile)
                }
            } catch (err) {
                console.error(err)
            }
        }

        fetchUserProfile()
    }, [token])

    const loginAction = async (data: ILogin) => {
        try {
            const token = await AuthService.login(data)
            const decodedToken: UserProfile = jwtDecode(token.token)
            const userProfile: UserProfile = {
                email: decodedToken.email,
                password: decodedToken.password,
                userId: decodedToken.userId,
                lineUserId: decodedToken.lineUserId,
                displayName: decodedToken.displayName,
                createDate: decodedToken.createDate,
                updateDate: decodedToken.updateDate,
                role: decodedToken.role,
                exp: decodedToken.exp,
            }

            setToken(token.token)
            setProfile(userProfile)
            localStorage.setItem('access_token', token.token)
            navigate('/home')
            notificationMessage({
                type: 'success',
                message: 'ยินดีต้อนรับเข้าสู่ระบบ',
            })
            return
        } catch (err: unknown) {
            console.error(err)
            if (err instanceof Error) {
                const axiosError = err as AxiosError
                console.log(axiosError.response)
                if (axiosError.code === 'ERR_BAD_REQUEST') {
                    setModalContent(
                        'อีเมลหรือรหัสผ่านไม่ถูกต้อง กรุณากรอกใหม่อีกครั้ง'
                    )
                }
                setIsModalOpen(true)
            }
        }
    }

    const logOut = () => {
        modal.confirm({
            title: 'คุณต้องการออกจากระบบหรือไม่',
            content: 'กรุณายืนยันการออกจากระบบ',
            icon: <ExclamationCircleFilled />,
            okText: 'ยืนยัน',
            cancelText: 'ยกเลิก',
            onOk: () => {
                setProfile(null)
                setToken('')
                localStorage.removeItem('access_token')
                navigate('/auth')
            },
            onCancel: () => {
                setIsModalOpen(false)
            },
        })
    }

    return (
        <AuthContext.Provider
            value={{ token, profile, setProfile, loginAction, logOut }}
        >
            {children}
            <Modal
                title="ไม่สามารถเข้าสู่ระบบได้"
                visible={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
            >
                <p>{modalContent}</p>
            </Modal>
            {contextHolder}
        </AuthContext.Provider>
    )
}
