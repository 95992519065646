import React, { useState } from 'react'
import { Form, FormInstance, Input, Select } from 'antd'
import { FieldData } from 'rc-field-form/lib/interface'
import { MasterDataField } from '../../../../model/enum/masterdata-field'
import TextArea from 'antd/es/input/TextArea'
import { UploadImg } from '../uploadImg'
import {
    PostMasterDataBody,
    UpdateMasterDataBody,
} from '../../../../model/interface/masterData'
import { DataTypeMaster } from '../../typemaster'
import { useAuth } from '../../../../context/Profile.context'

interface MasterDataFormProps {
    form: FormInstance
    onSubmit: (data: PostMasterDataBody) => void
    onFormChange?: (data: PostMasterDataBody) => void
    data: DataTypeMaster[] | null
    initialValues?: UpdateMasterDataBody | null
}

export const MasterDataForm = (props: MasterDataFormProps): JSX.Element => {
    const { form, onSubmit, initialValues } = props
    const [imageID, setImageID] = useState<string>('')
    const auth = useAuth()
    const hookSubmit = async (values: PostMasterDataBody) => {
        const profile = auth.profile

        if (profile) {
            const { email, userId } = profile
            onSubmit({
                ...values,
                bannerId: imageID,
                banner: values.banner,
                createBy: {
                    id:userId,
                    email: email,
                },
            })
        }
    }

    const onChangeForm = (_: FieldData[], allFields: PostMasterDataBody) => {
        if (props.onFormChange) {
            props.onFormChange(allFields)
        }
    }

    const handleUploadSuccess = (url: string, bannerID: string) => {
        setImageID(bannerID)
        form.setFieldsValue({
            [MasterDataField.MASTERDATA_URL]: url,
        })
    }
    const handleUploadDelete = async () => {
        form.setFieldsValue({
            [MasterDataField.MASTERDATA_URL]: '',
        })
    }

    return (
        <>
            <Form
                layout="vertical"
                name="basic"
                requiredMark={false}
                form={form}
                initialValues={initialValues || {}}
                onFinish={hookSubmit}
                onValuesChange={onChangeForm}
                autoComplete="off"
            >
                <Form.Item
                    name={[MasterDataField.MASTERDATA_TYPEID]}
                    label="ประเภทของ Master Data"
                    initialValue={initialValues?.typeId}
                    rules={[
                        {
                            required: true,
                            message: 'กรุณาเลือกประเภทของ Master Data',
                        },
                    ]}
                >
                    <Select
                        showSearch
                        placeholder="กรุณาเลือกประเภทของ Master Data"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            ((option?.label as string) ?? '').includes(input)
                        }
                        filterSort={(optionA, optionB) =>
                            ((optionA?.label as string) ?? '')
                                .toLowerCase()
                                .localeCompare(
                                    (
                                        (optionB?.label as string) ?? ''
                                    ).toLowerCase()
                                )
                        }
                        defaultValue={initialValues?.typeId}
                        options={
                            props.data
                                ? props.data.map((item: DataTypeMaster) => ({
                                      value: item.key,
                                      label: item.name,
                                  }))
                                : []
                        }
                    />
                </Form.Item>

                <Form.Item
                    name={[MasterDataField.MASTERDATA_NAME]}
                    label="ชื่อ"
                    initialValue={initialValues?.masterDataName}
                    rules={[
                        {
                            required: true,
                            message: 'กรุณากรอกชื่อ Master Data',
                        },
                    ]}
                >
                    <Input placeholder="กรุณากรอกชื่อ Master Data" />
                </Form.Item>

                <Form.Item
                    name={[MasterDataField.MASTERDATA_DESCRIPTION]}
                    label="คำอธิบาย"
                    initialValue={initialValues?.description}
                    rules={[
                        {
                            required: true,
                            message: 'กรุณากรอกคำอธิบาย',
                        },
                    ]}
                >
                    <TextArea
                        placeholder="กรุณากรอกคำอธิบาย"
                        autoSize={{ minRows: 1, maxRows: 3 }}
                    />
                </Form.Item>
                <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) =>
                        prevValues[MasterDataField.MASTERDATA_TYPEID] !==
                        currentValues[MasterDataField.MASTERDATA_TYPEID]
                    }
                >
                    {({ getFieldValue }) => {
                        const selectedType = getFieldValue(
                            MasterDataField.MASTERDATA_TYPEID
                        )
                        const hasBannerTrue = props.data?.some(
                            (item) =>
                                item.banner === true &&
                                item.key === selectedType
                        )
                        const shouldShowUpload = selectedType && hasBannerTrue

                        return (
                            <>
                                {shouldShowUpload && (
                                    <Form.Item
                                        name={[MasterDataField.MASTERDATA_URL]}
                                        label="รูปภาพ Banner"
                                        rules={[
                                            {
                                                validator: async (_, value) => {
                                                    if (!value) {
                                                        return Promise.reject(
                                                            'กรุณาอัปโหลดรูปภาพ Banner'
                                                        )
                                                    }
                                                },
                                            },
                                        ]}
                                    >
                                        <UploadImg
                                            onUploadImgSuccess={(url, id) => {
                                                console.log(
                                                    'Uploaded URL:',
                                                    url
                                                )
                                                console.log('Uploaded id:', id)
                                                handleUploadSuccess(url, id)
                                            }}
                                            initialValue={form.getFieldValue(
                                                'banner'
                                            )}
                                            onUploadImgDelete={()=> {
                                                handleUploadDelete()
                                            }}
                                        />
                                    </Form.Item>
                                )}
                            </>
                        )
                    }}
                </Form.Item>
            </Form>
        </>
    )
}
