import React, { Dispatch, SetStateAction } from 'react'
import { Content } from '../../../../model/interface/content'
import {
    Flex,
    Space,
    Button,
    Form,
    Input,
    Image,
    Empty,
    Spin,
    Typography,
    Divider,
} from 'antd'
import { ContentField } from '../../../../model/enum/content-field'
import { useNavigate } from 'react-router'
import { RoutePath } from '../../../../model/enum/route-path'
import { ContentService } from '../../../../service/content.service'
import { notificationMessage } from '../../../../components/notification'
import ConfirmationModal from '../../../../components/confirmationModal'
import styled from 'styled-components'

interface ListContentsProps {
    setDisabled: Dispatch<SetStateAction<boolean>>
}

const FlexText = styled(Flex)`
    flex-direction: column;
    div.ant-space {
        align-self: flex-end;
    }
    @media (max-width: 800px) {
        flex-direction: column-reverse;
        div.ant-space {
            align-self: center;
        }
    }
`
const FlexImg = styled(Flex)`
    @media (max-width: 800px) {
        flex: 1;
    }
`
const DetailContriner = styled.div`
    color: rgba(0, 0, 0, 0.25);
    background-color: rgba(0, 0, 0, 0.04);
    border: 1px solid #d9d9d9;
    border-radius: 6px;
    padding: 3px 11px;
    height: 235px;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    ::-webkit-scrollbar {
        display: none;
    }
`

const ListContents: React.FC<ListContentsProps> = ({ setDisabled }) => {
    const navigate = useNavigate()

    const [loading, setLoading] = React.useState(false)
    const [contents, setContents] = React.useState<Content[]>()

    const fetchContents = React.useCallback(async () => {
        try {
            setLoading(true)
            const contentsResponse = await ContentService.getAllContent()
            setContents(contentsResponse.data)
            setDisabled(contentsResponse.data?.length === 2)
            setLoading(false)
        } catch (error) {
            console.error('Error fetching content:', error)
            setLoading(false)
        }
    }, [setDisabled])
    React.useEffect(() => {
        fetchContents()
    }, [fetchContents])

    const handleEditContent = (id: string) => {
        navigate(`/${RoutePath.CONTENT}/edit/${id}`)
    }

    const handleDeleteContent = async (id: string) => {
        try {
            await ContentService.deletContent(id)
            fetchContents()
        } catch (error) {
            console.error('Error deleting blog:', error)
        } finally {
            notificationMessage({
                type: 'success',
                message: 'ลบ Content สำเร็จ',
            })
        }
    }

    const showDeleteConfirm = (id: string) => {
        ConfirmationModal({
            mode: 'delete',
            title: 'content',
            onOk: () => handleDeleteContent(id),
            okType: 'danger',
        })
    }

    return (
        <>
            {loading ? (
                <Flex
                    justify="center"
                    align="center"
                    style={{ height: '400px' }}
                >
                    <Spin />
                </Flex>
            ) : !contents ? (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            ) : (
                <Flex gap={24} vertical>
                    {contents?.map((item, index) => (
                        <Flex
                            key={item.id}
                            gap={32}
                            wrap="wrap"
                            vertical={true}
                        >
                            <FlexImg vertical gap={16}>
                                <Flex justify='space-between'>
                                    <Typography.Title
                                        level={3}
                                        style={{ margin: 0 }}
                                    >
                                        Content {index + 1}
                                    </Typography.Title>
                                    <Space>
                                        <Button
                                            onClick={() =>
                                                handleEditContent(item.id)
                                            }
                                        >
                                            แก้ไข Content {index + 1}
                                        </Button>
                                        <Button
                                            onClick={() =>
                                                showDeleteConfirm(item.id)
                                            }
                                            danger
                                        >
                                            ลบ Content {index + 1}
                                        </Button>
                                    </Space>
                                </Flex>
                                <Flex justify="center">
                                    <Image
                                        src={item.imageUrl}
                                        style={{
                                            width: '350px',
                                            objectFit: 'cover',
                                            aspectRatio: '1/1',
                                        }}
                                    />
                                </Flex>
                            </FlexImg>
                            <FlexText flex={1} gap={16}>
                                <Form
                                    layout="vertical"
                                    style={{ flex: 1.5 }}
                                    initialValues={{
                                        title: item.title,
                                        description: item.description,
                                    }}
                                >
                                    <Form.Item
                                        name={ContentField.TITLE}
                                        label="หัวข้อ Content"
                                    >
                                        <Input disabled />
                                    </Form.Item>
                                    <Form.Item
                                        name={ContentField.DESCRIPTION}
                                        label="รายละเอียด Content"
                                    >
                                        <DetailContriner
                                            dangerouslySetInnerHTML={{
                                                __html: item.description,
                                            }}
                                        ></DetailContriner>
                                    </Form.Item>
                                </Form>
                            </FlexText>
                            {contents.length === 2 && index === 0 && <Divider />}
                        </Flex>
                    ))}
                </Flex>
            )}
        </>
    )
}

export default ListContents
