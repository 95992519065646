import styled from 'styled-components'
import { Button, Form, Input } from 'antd'
import React from 'react'
import { LoginRequest } from '../../../model/interface/loginForm'
import { useAuth } from '../../../context/Profile.context'

const Container = styled.div`
    display: flex;
    height: 100vh;
`

const LeftSide = styled.div`
    flex: 1;
    background-color: #f0f0f0;
    display: flex;
    align-items: center;
    justify-content: center;
`

const Image = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
`

const RightSide = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
`

const ContentContainer = styled.div`
    display: flex;
    height: 304px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin: 67px 103px;
    gap: 24px;
`

const Heading = styled.h2`
    font-size: 36px;
    font-style: normal;
    font-weight: 500;
    margin: 0;
`

const Paragraph = styled.p`
    width: 453px;
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    margin: 0;
`

type ErrorInfoValues = {
    values: LoginRequest
    errorFields: {
        name: (string | number)[]
        errors: string[]
    }[]
    outOfDate: boolean
}

const LoginPage: React.FC = () => {
    const { loginAction } = useAuth()
    const onFinish = (values: LoginRequest) => {
        loginAction({ email: values.email, password: values.password })
    }

    const onFinishFailed = (errorInfo: ErrorInfoValues) => {
        console.log('Failed:', errorInfo)
    }

    return (
        <Container>
            <LeftSide>
                <Image src="/login-image.png" alt="LoginImage" />
            </LeftSide>

            <RightSide>
                <ContentContainer>
                    <Heading>สวัสดี ยินดีต้อนรับกลับมา! 🎉</Heading>
                    <Paragraph>
                        กรุณาเข้าสู่ระบบด้วยข้อมูลที่คุณได้ลงทะเบียนไว้
                    </Paragraph>
                </ContentContainer>

                <Form
                    name="basic"
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    requiredMark={false}
                    layout="vertical"
                    style={{
                        width: '100%',
                        padding: '67px 103px',
                        textAlign: 'left',
                    }}
                >
                    <Form.Item
                        label="อีเมล"
                        name="email"
                        rules={[
                            {
                                required: true,
                                message: 'กรุณากรอกอีเมลที่คุณได้ลงทะเบียนไว้',
                            },
                            {
                                pattern:
                                    /^[a-z0-9._-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                                message:
                                    'กรุณากรอกอีเมลเป็นตัวพิมพ์เล็กและรูปแบบที่ถูกต้อง',
                            },
                        ]}
                    >
                        <Input placeholder="กรุณากรอกอีเมลที่คุณได้ลงทะเบียนไว้" />
                    </Form.Item>

                    <Form.Item
                        label="รหัสผ่าน"
                        name="password"
                        rules={[
                            {
                                required: true,
                                message:
                                    'กรุณากรอกรหัสผ่านที่คุณได้ลงทะเบียนไว้',
                            },
                        ]}
                    >
                        <Input.Password placeholder="กรุณากรอกรหัสผ่านที่คุณได้ลงทะเบียนไว้" />
                    </Form.Item>
                    <Form.Item>
                        <Button
                            type="primary"
                            htmlType="submit"
                            style={{ width: '100%' }}
                        >
                            เข้าสู่ระบบ
                        </Button>
                    </Form.Item>
                </Form>
            </RightSide>
        </Container>
    )
}

export default LoginPage
