import React from 'react'
import {
    Button,
    Form,
    FormInstance,
    FormListFieldData,
    FormListOperation,
    Space,
    Typography,
} from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { BlogField, BlogContent } from '../../../../model/enum/blog'
import InputDetail from '../../../../components/inputDetail'
import InputDragUploadImg from '../../../../components/inputDragUploadImg'
import '../styles.css'

interface SubBlogFormProps {
    form: FormInstance
    fields: FormListFieldData[]
    operations: FormListOperation
    errors: React.ReactNode[]
}

const SubBlogForm: React.FC<SubBlogFormProps> = ({
    form,
    fields,
    operations,
    errors,
}) => {
    const watchValues = Form.useWatch([], form)

    return (
        <>
            {fields.map((field, index) => {
                const type = form.getFieldValue([
                    BlogField.CONTENT,
                    field.name,
                    'type',
                ])
                return (
                    <div key={field.key}>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }}
                        >
                            <Typography.Paragraph style={{ margin: 0 }}>
                                ส่วนย่อยที่ {index + 1}
                            </Typography.Paragraph>
                            <Button
                                type="dashed"
                                style={{ margin: '8px 0' }}
                                onClick={() => {
                                    operations.remove(field.name)
                                }}
                            >
                                ลบ
                            </Button>
                        </div>

                        <Form.Item
                            name={[field.name, 'description']}
                            rules={[
                                {
                                    required: true,
                                    message:
                                        type === BlogContent.DESCRIPTION
                                            ? `กรุณากรอกส่วนย่อยส่วนที่ ${index + 1}`
                                            : `กรุณาอัปโหลดรูปภาพส่วนย่อยส่วนที่ ${index + 1}`,
                                },
                            ]}
                        >
                            {type === BlogContent.DESCRIPTION ? (
                                <InputDetail
                                    form={form}
                                    name={['content', index, 'description']}
                                    placeholder={`กรุณากรอกเนื้อหาส่วนย่อยที่ ${
                                        index + 1
                                    }`}
                                />
                            ) : (
                                <InputDragUploadImg
                                    initialValue={
                                        watchValues?.content[field.name]
                                            ?.description
                                    }
                                    form={form}
                                    name={[
                                        'content',
                                        field.name,
                                        'description',
                                    ]}
                                    aspectRatioRecomment={'16:9'}
                                    sizeRecomment={'225x200'}
                                />
                            )}
                        </Form.Item>
                    </div>
                )
            })}
            <Space>
                <Button
                    type="dashed"
                    onClick={() => {
                        operations.add()
                        form.setFieldValue(
                            ['content', fields.length, 'type'],
                            BlogContent.DESCRIPTION
                        )
                    }}
                    icon={<PlusOutlined />}
                    style={{ width: '100%' }}
                >
                    เพิ่มเนื้อหา
                </Button>
                <Button
                    type="dashed"
                    onClick={() => {
                        operations.add()
                        form.setFieldValue(
                            ['content', fields.length, 'type'],
                            BlogContent.IMAGE_URL
                        )
                    }}
                    icon={<PlusOutlined />}
                    style={{ width: '100%' }}
                >
                    เพิ่มรูป
                </Button>
            </Space>
            <Form.ErrorList className="custom-error-list" errors={errors} />
        </>
    )
}

export default SubBlogForm
