import React, { useEffect, useState } from 'react'
import PageLayout from '../../../components/page-layout'
import { Button, Form, Space } from 'antd'
import { useNavigate, useParams } from 'react-router'
import { notificationMessage } from '../../../components/notification'
import ConfirmationModal, {
    ActionType,
} from '../../../components/confirmationModal'
import { IAdmin, IAdminForm } from '../../../model/interface/admin'
import { AdminService } from '../../../service/admin.service'
import { AdminForm } from '../components/adminForm'

export const AdminDetail: React.FC = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [adminData, setAdminData] = useState<IAdmin>()
    const navigate = useNavigate()
    const [form] = Form.useForm()
    const [disabled, setDisabled] = useState(false)
    const { id } = useParams<{ id: string }>()

    const onSubmitForm = async (formData: IAdminForm) => {
        // setDisabled(true)
        showEditConfirm(formData)
    }

    useEffect(() => {
        if (id) {
            const fetchData = async () => {
                try {
                    const admin = await AdminService.getAdmin(id)
                    console.log(admin)

                    form.setFieldsValue(admin)
                    setAdminData(adminData)
                } catch (error) {
                    console.error('Error fetching admin data:', error)
                }
            }

            fetchData()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleUpdate = async (req: IAdminForm) => {
        try {
            setDisabled(true)
            if (id) {
                await AdminService.updateAdmin(id, req).then(() => {
                    notificationMessage({
                        type: 'success',
                        message: 'แก้ไข Admin สำเร็จ',
                    })
                })
            }
            navigate(`/admin`)
        } catch (error) {
            const errorMessage = (
                error as { response: { data: { error: string } } }
            ).response.data.error
            if (errorMessage === 'incorrect old password') {
                notificationMessage({
                    type: 'error',
                    message:
                        'รหัสผ่านเดิมไม่ถูกต้อง กรุณากรอกรหัสผ่านเดิมให้ถูกต้อง',
                })
            } else {
                notificationMessage({
                    type: 'error',
                    message: 'แก้ไข Admin ไม่สำเร็จ',
                })
            }
            form.setFieldsValue({
                oldPassword: '',
                password: '',
                confirmPassword: '',
            })
            setDisabled(false)
        }
    }

    const showCancelConfirm = () => {
        ConfirmationModal({
            mode: 'cancelUpdate' as ActionType,
            title: 'Admin',
            onOk: () => navigate('/admin'),
        })
    }

    const showEditConfirm = (req: IAdminForm) => {
        ConfirmationModal({
            mode: 'update' as ActionType,
            title: 'Admin',
            onOk: () => handleUpdate(req),
        })
    }
    return (
        <>
            <PageLayout
                title="แก้ไข Admin"
                action={
                    <Space>
                        <Button
                            type="default"
                            onClick={() => showCancelConfirm()}
                            disabled={disabled}
                        >
                            ยกเลิก
                        </Button>
                        <Button
                            type="primary"
                            disabled={disabled}
                            onClick={() => form.submit()}
                        >
                            บันทึก
                        </Button>
                    </Space>
                }
            >
                <div>
                    <AdminForm
                        form={form}
                        onSubmit={onSubmitForm}
                        initialValues={adminData}
                        mode="edit"
                    />
                </div>
            </PageLayout>
        </>
    )
}
