import {
    MasterDataTypeResponse,
    PostMasterDataBody,
    PostMasterDataTypeBody,
    MasterDataItemParams,
    MasterDataItemResponse,
    UpdateMasterDataBody,
    MasterDataItem,
    MasterData,
    MasterDataApiResponse,
} from '../model/interface/masterData'
import { MasterDataParams } from '../model/interface/masterData'
import { prefixApi } from './prefix.api'
import { axiosInstance } from './axios.api'
import axios, { AxiosError } from 'axios'

export class MasterDataService {
    private static masterDataUrl = `${prefixApi.masterData}`

    public static async getAllMasterDataType(
        refCode?: MasterDataParams
    ): Promise<MasterDataTypeResponse> {
        try {
            const url = refCode
                ? `${this.masterDataUrl}/types?search=${refCode.search}`
                : `${this.masterDataUrl}/types`
            const res = await axiosInstance.get<MasterDataTypeResponse>(
                `${url}`
            )
            return res.data
        } catch (error) {
            console.error('Error fetching data:', error)
            throw error
        }
    }

    public static async getAllMasterData(
        refCode?: MasterDataItemParams
    ): Promise<MasterDataItemResponse> {
        try {
            const url = refCode
                ? `${this.masterDataUrl}/datas?search=${refCode.search}&masterDataType=${refCode.masterDataType}&page=${refCode.page}&pageSize=${refCode.pageSize}&total=${refCode.total}`
                : `${this.masterDataUrl}/datas`
            const res = await axiosInstance.get<MasterDataItemResponse>(
                `${url}`
            )
            return res.data
        } catch (error) {
            console.error('Error fetching data:', error)
            throw error
        }
    }

    public static async postMasterDataItem(
        body: PostMasterDataBody
    ): Promise<void> {
        try {
            const url = `${this.masterDataUrl}/data`
            await axiosInstance.post(url, body)
        } catch (error) {
            if (axios.isAxiosError(error)) {
                const axiosError = error as AxiosError
                if (axiosError.response) {
                    throw axiosError.response.data
                } else if (axiosError.request) {
                    throw axiosError.request
                } else {
                    throw axiosError.message
                }
            } else {
                throw error
            }
        }
    }

    public static async postMasterDataType(
        body: PostMasterDataTypeBody
    ): Promise<void> {
        try {
            const url = `${this.masterDataUrl}/type`
            await axiosInstance.post(url, body)
        } catch (error: unknown) {
            if (axios.isAxiosError(error)) {
                const axiosError = error as AxiosError
                if (axiosError.response) {
                    throw axiosError.response.data
                } else if (axiosError.request) {
                    throw axiosError.request
                } else {
                    throw axiosError.message
                }
            } else {
                throw error
            }
        }
    }

    public static async deleteMasterDataType(dataId: string): Promise<void> {
        const url = `${this.masterDataUrl}/data/${dataId}`
        await axiosInstance.delete(url)
    }

    public static async updateMasterDataType(
        dataId: string,
        requestBody: UpdateMasterDataBody
    ): Promise<void> {
        try {
            const url = `${this.masterDataUrl}/data/${dataId}`
            const response = await axiosInstance.put(url, requestBody)
            return response.data
        } catch (error: unknown) {
            if (axios.isAxiosError(error)) {
                const axiosError = error as AxiosError
                if (axiosError.response) {
                    throw axiosError.response.data
                } else if (axiosError.request) {
                    throw axiosError.request
                } else {
                    throw axiosError.message
                }
            } else {
                throw error
            }
        }
    }

    public static async getMasterDataTypeByID(
        editID: string
    ): Promise<MasterDataItem> {
        try {
            const url = `${this.masterDataUrl}/data/${editID}`
            const response = await axiosInstance.get<MasterDataItem>(`${url}`)
            return response.data
        } catch (error: unknown) {
            if (axios.isAxiosError(error)) {
                const axiosError = error as AxiosError
                if (axiosError.response) {
                    throw axiosError.response.data
                } else if (axiosError.request) {
                    throw axiosError.request
                } else {
                    throw axiosError.message
                }
            } else {
                throw error
            }
        }
    }

    public static async getMasterDataProductCategory(): Promise<MasterDataApiResponse> {
        const res = await axiosInstance.get<MasterDataApiResponse>(
            `${this.masterDataUrl}/code/product_category`
        )
        return res.data
    }

    public static async getAllMasterDataTypes(): Promise<MasterData> {
        const res = await axiosInstance.get<MasterData>(
            `${this.masterDataUrl}/types`
        )
        return res.data
    }
}
