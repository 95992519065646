/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'
import PageLayout from '../../components/page-layout'
import { useNavigate } from 'react-router'
import { Button, Space } from 'antd'
import { RoutePath } from '../../model/enum/route-path'
import ListContents from './components/listContents'

const ContentPage: React.FC = () => {
    const navigate = useNavigate()

    const [disabled, setDisabled] = React.useState(false)

    return (
        <PageLayout
            title="ระบบจัดการ Content"
            action={
                <Space>
                    <Button
                        type="primary"
                        disabled={disabled}
                        onClick={() => navigate(`/${RoutePath.CONTENT}/create`)}
                    >
                        เพิ่ม Content
                    </Button>
                </Space>
            }
        >
            <ListContents setDisabled={setDisabled} />
        </PageLayout>
    )
}

export default ContentPage
