import {
    AllBlogApiResponse,
    Blog,
    BlogCreate,
    BlogDeleteResponse,
    BlogEdit,
    BlogEditResponse,
} from '../model/interface/blog'
import { AxiosResponse } from 'axios'
import { prefixApi } from './prefix.api'
import { axiosInstance } from './axios.api'

export class BlogService {
    private static url = `${prefixApi.blog}`

    public static async getAllBlog(
        page: number,
        pageSize: number,
        search?: string
    ): Promise<AllBlogApiResponse> {
        let res: AxiosResponse<AllBlogApiResponse>

        if (search !== undefined) {
            res = await axiosInstance.get<AllBlogApiResponse>(
                `${this.url}s?page=${page}&pageSize=${pageSize}&search=${search}`
            )
        } else {
            res = await axiosInstance.get<AllBlogApiResponse>(
                `${this.url}s?page=${page}&pageSize=${pageSize}`
            )
        }

        return res.data
    }

    public static async getBlog(id: string): Promise<Blog> {
        const res = await axiosInstance.get<Blog>(`${this.url}/${id}`)
        return res.data
    }

    public static async createBlog(req: BlogCreate): Promise<Blog> {
        const res = await axiosInstance.post<Blog>(`${this.url}`, req)
        return res.data
    }

    public static async updateBlog(
        id: string,
        req: BlogEdit
    ): Promise<BlogEditResponse> {
        const res = await axiosInstance.put<BlogEditResponse>(
            `${this.url}/${id}`,
            req
        )
        return res.data
    }

    public static async deleteBlog(id: string): Promise<BlogDeleteResponse> {
        const res = await axiosInstance.delete<BlogDeleteResponse>(
            `${this.url}/${id}`
        )
        return res.data
    }
}
