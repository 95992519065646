import React, { useEffect, useState } from 'react'
import { PaymentApiResponse } from '../../model/interface/payment'
import PageLayout from '../../components/page-layout'
import { PaymentService } from '../../service/payment.service'
import { PaymentList } from './components/list'
import SearchForm from './components/searchForm'
import { Spin } from 'antd'

export interface FilterData {
    page: number
    pageSize: number
    userId?: string
    productId?: string
    status?: string
    startDate?: string
    endDate?: string
    refId?: string
}

const PaymentPage: React.FC = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [dataSource, setDataSource] = useState<PaymentApiResponse>()
    const [filterData, setFilterData] = useState<FilterData>({
        page: 1,
        pageSize: 10,
        userId: '',
        productId: '',
        status: '',
        startDate: '',
        endDate: '',
        refId: '',
    })

    useEffect(() => {
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterData])

    const fetchData = async () => {
        try {
            setIsLoading(true)
            const product = await PaymentService.getPayments(
                filterData.page,
                filterData.pageSize,
                filterData.refId,
                filterData.userId,
                filterData.productId,
                filterData.status,
                filterData.startDate,
                filterData.endDate
            )
            setDataSource(product)
            setIsLoading(false)
        } catch (error) {
            console.error('Error fetching data:', error)
        }
    }

    const handleSearch = (values: FilterData) => {
        setFilterData({
            ...filterData,
            userId: values.userId || '',
            productId: values.productId || '',
            status: values.status || '',
            startDate: values.startDate || '',
            endDate: values.endDate || '',
        })
    }

    return (
        <PageLayout title="ระบบชำระเงิน">
            <SearchForm onSearch={handleSearch} />
            <Spin spinning={isLoading}>
                <PaymentList
                    dataSource={dataSource}
                    setFilterAllPayment={setFilterData}
                />
            </Spin>
        </PageLayout>
    )
}

export default PaymentPage
