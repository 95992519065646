import React from 'react'
import { Form, Input } from 'antd'
import { Blog } from '../../../../model/interface/blog'
import { BlogField } from '../../../../model/enum/blog'
import InputDetail from '../../../../components/inputDetail'
import InputDragUploadImg from '../../../../components/inputDragUploadImg'
import SubBlogForm from '../subBlogForm'
import useFormBlog from './useBlogForm'

export const BlogForm: React.FC = () => {
    const { form, initialValues, isCreate, confirmCreateEditBlog } =
        useFormBlog()

    const onFinish = async (values: Blog) => {
        confirmCreateEditBlog(isCreate, values)
    }

    return (
        <Form
            id="blog-form"
            layout="vertical"
            requiredMark={false}
            form={form}
            initialValues={initialValues || {}}
            onFinish={onFinish}
            autoComplete="off"
        >
            <Form.Item
                style={{ display: 'flex', justifyContent: 'center' }}
                name={[BlogField.IMAGE]}
                label="รูปภาพ Banner สำหรับบทความ"
                rules={[{ required: true, message: 'กรุณาอัปโหลดรูปภาพ' }]}
            >
                <InputDragUploadImg
                    form={form}
                    name={BlogField.IMAGE}
                    initialValue={initialValues?.image}
                    aspectRatioRecomment={'16:9'}
                    sizeRecomment={'225x200'}
                />
            </Form.Item>
            <Form.Item
                name={[BlogField.TITLE]}
                label="ชื่อบทความ"
                rules={[{ required: true, message: 'กรุณากรอกชื่อบทความ' }]}
            >
                <Input placeholder="กรุณากรอกชื่อบทความ" />
            </Form.Item>
            <Form.Item
                name={[BlogField.SHORT_DESC]}
                label="คำอธิบายบทความโดยย่อ"
                rules={[
                    {
                        required: true,
                        message: 'กรุณากรอกคำอธิบายบทความโดยย่อ',
                    },
                ]}
            >
                <InputDetail
                    form={form}
                    name={BlogField.SHORT_DESC}
                    placeholder="กรุณากรอกคำอธิบายบทความโดยย่อ"
                />
            </Form.Item>

            <Form.List
                name={BlogField.CONTENT}
                rules={[
                    {
                        validator: async (_, contents) =>
                            (contents && contents.length >= 1) ||
                            Promise.reject(
                                new Error('ควรมีอย่างน้อยหนึ่งส่วนย่อย')
                            ),
                    },
                ]}
            >
                {(fields, operations, { errors }) => (
                    <SubBlogForm
                        form={form}
                        fields={fields}
                        operations={operations}
                        errors={errors}
                    />
                )}
            </Form.List>
        </Form>
    )
}
