import React, { useEffect, useMemo, useState } from 'react'
import {
    HomeOutlined,
    ProjectOutlined,
    LeftOutlined,
    RightOutlined,
    AppstoreAddOutlined,
    ContainerOutlined,
    PicLeftOutlined,
    DatabaseOutlined,
    UserOutlined,
    DollarOutlined,
} from '@ant-design/icons'
import type { MenuProps } from 'antd'
import { Button, Layout, Menu } from 'antd'
import { Outlet, useNavigate } from 'react-router'
import { ColorsConstant } from '../assets/colors/Colors'
import '../assets/colors/Layout.scss'
import UserProfile from './components/user-profile'
import { useAuth } from '../context/Profile.context'
import './index.scss'
import LogoComponent from './components/logo'
import { ItemType } from 'antd/lib/menu/interface'

const { Footer, Sider } = Layout

type CoquteItemType = ItemType

const NavigationLayout: React.FC = () => {
    const navigate = useNavigate()
    const auth = useAuth()
    const [collapsed, setCollapsed] = useState(false)
    const [selectedKeys, setSelectedKeys] = useState<string[]>([''])
    const [defaultKey, setDefaultKey] = useState<string>('home')

    const menuItems: CoquteItemType[] = useMemo(() => {
        const items: CoquteItemType[] = [
            {
                key: 'home',
                icon: <HomeOutlined />,
                label: 'หน้าแรก',
            },
            {
                key: 'admin',
                icon: <UserOutlined />,
                label: 'Admin',
            },
            {
                key: 'banner',
                icon: <ContainerOutlined />,
                label: 'Banner',
            },
            {
                key: 'product',
                icon: <AppstoreAddOutlined />,
                label: 'สินค้า',
            },
            {
                key: 'masterdata',
                icon: <DatabaseOutlined />,
                label: 'จัดการข้อมูล',
            },
            {
                key: 'content',
                icon: <PicLeftOutlined />,
                label: 'เนื้อหา Content',
            },
            {
                key: 'blog',
                icon: <ProjectOutlined />,
                label: 'บทความ',
            },
            {
                key: 'payment',
                icon: <DollarOutlined />,
                label: 'ระบบชำระเงิน',
            },
        ]
        return items
    }, [])

    useEffect(() => {
        if (auth.profile) {
            // ตรวจสอบ role ของ user และกำหนด default tab ตามต้องการ
            const defaultTabKey =
                auth.profile.role === 'admin' ? 'masterdata' : 'home'
            setDefaultKey(defaultTabKey)

            // ตรวจสอบ selected tab และกำหนด selectedKeys
            const path = location.pathname.split('/')
            const selectedTabKey = path[1] || defaultTabKey
            setSelectedKeys([selectedTabKey])
        }
    }, [auth.profile, location.pathname])

    const navigateWithKey: MenuProps['onClick'] = (menu) => {
        if (menu.keyPath.length > 1) {
            navigate(`${menu.keyPath[1]}/${menu.keyPath[0]}`)
        } else {
            navigate(menu.key)
        }
        setSelectedKeys([menu.key])
    }

    const onCollapse = () => setCollapsed(!collapsed)

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Sider
                breakpoint="lg"
                collapsible
                collapsed={collapsed}
                onCollapse={onCollapse}
                trigger={null}
                data-testid="sider-nav"
                className="sider-nav"
                style={{
                    height: '100vh',
                }}
                theme="light"
            >
                <div className="navigate">
                    <div className="navigation">
                        <div className="logo">
                            <LogoComponent collapsed={collapsed} />
                        </div>
                        <div className="menu">
                            <Menu
                                defaultSelectedKeys={[defaultKey]}
                                selectedKeys={selectedKeys}
                                mode="inline"
                                items={menuItems}
                                onClick={navigateWithKey}
                            />
                        </div>
                    </div>
                    <UserProfile
                        collapsed={collapsed}
                        profile={auth.profile}
                        onLogout={auth.logOut}
                    />
                </div>
                <Button
                    type="link"
                    icon={collapsed ? <RightOutlined /> : <LeftOutlined />}
                    onClick={onCollapse}
                    className="collapse-button"
                />
            </Sider>
            <Layout
                className="site-layout"
                style={{ marginLeft: collapsed ? 80 : 200 }}
            >
                <Outlet />
                <Footer
                    className="footer"
                    style={{ color: ColorsConstant.navbar_color_text }}
                >
                    <p>COQUTE. BEAUTY ADMIN</p>
                </Footer>
            </Layout>
        </Layout>
    )
}

export default NavigationLayout
