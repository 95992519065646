/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'
import { DatePicker, Form, Input, Select } from 'antd'
import { MasterDataService } from '../../../../service/masterData.service'
import styled from 'styled-components'
import { CustomerService } from '../../../../service/customer.service'
import { VerifiedStatus } from '../../../../model/enum/verified-status'
import { FilterData } from '../..'
import { ProductService } from '../../../../service/product.service'

const FormContainer = styled(Form)`
    display: grid;
    grid-template-columns: auto auto;
    gap: 16px;
    margin-bottom: 16px;
    .ant-form-item {
        margin: 0;
    }
    @media (max-width: 768px) {
        grid-template-columns: auto;
    }
`

interface SearchFormProps {
    onSearch: (values: FilterData) => void
}

const SearchForm: React.FC<SearchFormProps> = ({ onSearch }) => {
    const [form] = Form.useForm()

    const [category, setCategory] = React.useState<
        { label: string; value: string }[]
    >([])
    const [customer, setCustomer] = React.useState<
        { label: string; value: string }[]
    >([])
    const [product, setProduct] = React.useState<
        { label: string; value: string }[]
    >([])

    const fetchProductCategory = async () => {
        const categoryResponse =
            await MasterDataService.getMasterDataProductCategory()
        setCategory(
            categoryResponse.data.map((item) => ({
                label: item.masterDataName,
                value: item.id,
            }))
        )
    }
    const fetchCustomer = async () => {
        const customerResponse = await CustomerService.getAllCustomer()
        setCustomer(
            customerResponse.data.map((item) => ({
                label: item.displayName,
                value: item.userId,
            }))
        )
    }
    const fetchProduct = async (categoryId: string) => {
        const productResponse = await ProductService.getAllProduct(
            1,
            10,
            '',
            categoryId,
            false
        )
        setProduct(
            productResponse.data.map((item) => ({
                label: item.title,
                value: item.id,
            }))
        )
    }

    const verifyStatus = [
        { value: VerifiedStatus.FAILED, label: 'จ่ายเงินไม่สำเร็จ' },
        { value: VerifiedStatus.WAITING_VERIFY, label: 'กำลังรอการตรวจสอบ' },
        { value: VerifiedStatus.UNVERIFIED, label: 'ไม่อนุมัติการจ่ายเงิน' },
        { value: VerifiedStatus.VERIFIED, label: 'จ่ายเงินสำเร็จ' },
    ]

    const handleSearch = (name: string) => {
        form.validateFields().then((values) => {
            if (name == 'category') {
                // Reset productId in the form
                form.setFieldsValue({ productId: undefined })
            }
            onSearch(values)
        })
    }

    React.useEffect(() => {
        fetchProductCategory()
        fetchCustomer()
    }, [])

    const formValues = Form.useWatch([], form)

    React.useEffect(() => {
        if (formValues?.category) {
            fetchProduct(formValues.category)
        }
    }, [formValues?.category])

    return (
        <FormContainer form={form}>
            <Form.Item name="userId">
                <Select
                    options={customer}
                    placeholder="ค้นหาชื่อผู้ใช้งาน"
                    allowClear
                    onChange={() => handleSearch('userId')}
                />
            </Form.Item>
            <Form.Item name="status">
                <Select
                    placeholder="กรองด้วยสถานะการจ่ายเงิน"
                    options={verifyStatus}
                    allowClear
                    onChange={() => handleSearch('status')}
                />
            </Form.Item>
            <Form.Item name="category">
                <Select
                    placeholder="กรองด้วยหมวดหมู่ของสินค้า"
                    style={{ width: '100%' }}
                    options={category}
                    allowClear={formValues?.productId ? false : true}
                    onChange={() => handleSearch('category')}
                />
            </Form.Item>
            <Form.Item name="productId">
                <Select
                    disabled={formValues?.category ? false : true}
                    placeholder="ค้นหาสินค้าในระบบ"
                    options={product}
                    allowClear
                    onChange={() => handleSearch('productId')}
                />
            </Form.Item>
            <Form.Item name="startDate">
                <DatePicker
                    placeholder="วันที่เริ่มต้น"
                    style={{ width: '100%' }}
                    onChange={() => handleSearch('startDate')}
                />
            </Form.Item>
            <Form.Item name="endDate">
                <DatePicker
                    placeholder="วันที่สิ้นสุด"
                    style={{ width: '100%' }}
                    onChange={() => handleSearch('endDate')}
                />
            </Form.Item>
        </FormContainer>
    )
}

export default SearchForm
