import React from 'react'
import { Tabs } from 'antd'
import type { TabsProps } from 'antd'
import ProductReccomment from '../../productRecommend'
import ProductAnother from '../../anotherProduct'
import AllProduct from '../../allProduct'

interface TabProps {
    onChange: (key: string) => void
    isEditing: boolean
    isEditingAnother: boolean
    setUpdataChecked: React.Dispatch<React.SetStateAction<string[]>>
    setUpdataCheckedAnother: React.Dispatch<React.SetStateAction<{ productId: string }[]>>
    onClickCancel: boolean
    onClickCancelRec: boolean
    isToggleRec: boolean;
    isToggleAno: boolean;
    isDisabledRec: (disabled: boolean) => void 
    isDisabledAno: (disabled: boolean) => void 
}

const Tab = ({
    onChange,
    isEditing,
    isEditingAnother,
    setUpdataChecked,
    setUpdataCheckedAnother,
    onClickCancel,
    onClickCancelRec,
    isToggleRec,
    isToggleAno,
    isDisabledRec,
    isDisabledAno
}: TabProps): JSX.Element => {
    const items: TabsProps['items'] = [
        {
            key: '1',
            label: 'สินค้าทั้งหมด',
            children: <AllProduct />,
        },
        {
            key: '2',
            label: 'สินค้าแนะนำ',
            children: <ProductReccomment isDisabledRec={isDisabledRec} isToggleRec={isToggleRec} isEditing={isEditing} setUpdataChecked={setUpdataChecked} onClickCancelRec={onClickCancelRec}/>,
        },
        {
            key: '3',
            label: 'สินค้าอื่นๆ',
            children: (
                <>
                    <ProductAnother isDisabledAno={isDisabledAno} isToggleAno={isToggleAno} isEditingAnother={isEditingAnother} setUpdataCheckedAnother={setUpdataCheckedAnother} onClickCancel={onClickCancel}/>
                </>
            ),
        },
    ]

    return (
        <>
            <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
        </>
    )
}

export default Tab