// AppContext.tsx
import React, { createContext, useContext, useState, ReactNode } from "react";
import { AppContextType } from "../model/interface/contextType";

const AppContext = createContext<AppContextType | undefined>(undefined);

const AppProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [notificationState, setNotificationState] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  const updateNotificationState = (newState: boolean) => {
    setNotificationState(newState);
  };

  const updateMenuOpen = (newState: boolean) => {
    setMenuOpen(newState);
  };

  const contextValue: AppContextType = {
    notificationState,
    updateNotificationState,
    menuOpen,
    updateMenuOpen
  };

  return <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>;
};

const useAppContext = (): AppContextType => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error("useAppContext must be used within an AppProvider");
  }
  return context;
};

export { AppProvider, useAppContext };
