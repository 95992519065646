export enum BlogContent {
    DESCRIPTION = 'Description',
    IMAGE_URL = 'ImageURL',
}

export enum BlogField {
    IMAGE = 'image',
    TITLE = 'title',
    SHORT_DESC = 'shortDesc',
    CONTENT = 'content',
}

