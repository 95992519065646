import React from 'react'
import { Layout } from 'antd'

interface PageLayoutProps {
    title?: React.ReactNode
    action?: React.ReactNode
    children: React.ReactNode
}

const { Header, Content } = Layout

const PageLayout = ({
    title,
    action,
    children,
}: PageLayoutProps): React.ReactElement => {
    return (
        <>
            <Header
                className="site-layout-background header"
                style={{ zIndex: 999 }}
                data-testid="page-header"
            >
                <div className="header-title">
                    <span
                        className="header-title-text"
                        data-testid="page-title"
                    >
                        {title}
                    </span>
                </div>
                <div className="header-right" data-testid="page-action">
                    {action}
                </div>
            </Header>
            <Content className="content" data-testid="page-content">
                <div className="site-layout-background">{children}</div>
            </Content>
        </>
    )
}

export default PageLayout
