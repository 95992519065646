export const ColorsConstant = {
    primary: '#7DBFFC',
    secondary: '#ffffff',
    primary_active: '#999999',
    primary_light: '#cccccc',
    primary_inverse: '#f4f4f4',
    success: '#52c41a',
    success_hover: '#73d13d',
    success_active: '#389e0d',
    success_light: '#52c41a',

    info: '#1890ff',
    info_hover: '#40a9ff',
    info_active: '#096dd9',
    info_light: '#eee5ff',

    warning: '#faad14',
    warning_hover: '#ffc53d',
    warning_active: '#d48806',
    warning_light: '#fff4de',

    danger: '#ff4d4f',
    danger_hover: '#ff7875',
    danger_active: '#d9363e',
    danger_light: '#ffe2e5',

    navbar_color_background: '#ffffff',
    navbar_color_text: 'rgba(0, 0, 0, 0.88)',

    item_active_color: '#000000',

    footer_color_background: '#ffffff',
    footer_color_text: '#000000',

    color_border: '#d9d9d9',
    // color_primary_hover: '#00000080',
}

export const Text_base = {
    fontFamily: 'inter',
    fontSize: 16,
}
