import React, { useState } from 'react'
import PageLayout from '../../components/page-layout'
import { Button, Space, Modal, Form, Input, Checkbox } from 'antd'
import { useNavigate } from 'react-router'
import { RoutePath } from '../../model/enum/route-path'
import { Tabs } from 'antd'
import type { CheckboxProps, TabsProps } from 'antd'
import TypeMasterData from './typemaster'
import MasterData from './masterdata'
import TextArea from 'antd/es/input/TextArea'
import { MasterDataField } from '../../model/enum/masterdata-field'
import { PostMasterDataTypeBody } from '../../model/interface/masterData'
import { MasterDataService } from '../../service/masterData.service'
import { notificationMessage } from '../../components/notification'
import ConfirmationModal, {
    ActionType,
} from '../../components/confirmationModal'

const onChange = (key: string) => {
    console.log(key)
}

const items: TabsProps['items'] = [
    {
        key: '1',
        label: 'ประเภทของ Master Data',
        children: <TypeMasterData />,
    },
    {
        key: '2',
        label: 'Master Data',
        children: <MasterData />,
    },
]

const MasterDataPage = (): JSX.Element => {
    const navigate = useNavigate()
    const [isCreateTypeModalVisible, setIsCreateTypeModalVisible] =
        useState(false)
    const [form] = Form.useForm()
    const [bannerCheck, setBannerCheck] = useState(false)

    const showCreateTypeModal = () => {
        setIsCreateTypeModalVisible(true)
    }

    const handleCreateTypeModalOk = () => {
        form.validateFields()
            .then(() => {
                form.submit()
                setIsCreateTypeModalVisible(false)
            })
            .catch(() => {
                // Validation failed, do nothing
            })
    }

    const hookSubmit = async (values: PostMasterDataTypeBody) => {
        try {
            const refCode = values[MasterDataField.MASTERDATA_REFCODE]
            const formattedRefCode = refCode.toLowerCase().replace(/\s+/g, '_')
            const firstTwoWords = formattedRefCode
                .split('_')
                .slice(0, 2)
                .join('_')
            const postData: PostMasterDataTypeBody = {
                ...values,
                [MasterDataField.MASTERDATA_REFCODE]: firstTwoWords,
                banner: bannerCheck,
                createBy: {
                    id: '5555',
                    email: 'test@example.com',
                },
            }
            await MasterDataService.postMasterDataType(postData)
            setIsCreateTypeModalVisible(false)
            notificationMessage({
                type: 'success',
                message: 'สร้างประเภท Master Data สำเร็จ',
            })
            form.resetFields()
            setBannerCheck(false)
            window.location.reload()
        } catch (error) {
            let errorMessage: string = 'An unexpected error occurred'
            if (
                typeof error === 'object' &&
                error !== null &&
                'error' in error
            ) {
                errorMessage = (error as { error: string }).error
            }
            setIsCreateTypeModalVisible(true)
            console.log(errorMessage)
            if (errorMessage === 'refCode already exists') {
                notificationMessage({
                    type: 'error',
                    message:
                        'ไม่สามารถสร้างประเภท Master Data ที่ refCode ซ้ำกันได้',
                })
            } else {
                notificationMessage({
                    type: 'error',
                    message: 'สร้างประเภท Master Data ไม่สำเร็จ',
                })
            }
        }
    }

    const handleCreateTypeModalCancel = () => {
        setIsCreateTypeModalVisible(false)
        form.resetFields()
        setBannerCheck(false)
    }

    const onChangeCheck: CheckboxProps['onChange'] = (e) => {
        console.log(`checked = ${e.target.checked}`)
        setBannerCheck(e.target.checked)
    }

    return (
        <PageLayout
            title="ระบบจัดการข้อมูล"
            action={
                <Space>
                    <Button type="primary" onClick={showCreateTypeModal}>
                        สร้างประเภทของ Master Data
                    </Button>
                    <Button
                        onClick={() => navigate(`/${RoutePath.MASTER}/create`)}
                        type="primary"
                    >
                        สร้าง Master Data
                    </Button>
                </Space>
            }
        >
            <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
            <Modal
                title="สร้างประเภทของ Master Data"
                open={isCreateTypeModalVisible}
                closable={false}
                footer={[
                    <Button key="cancel" onClick={handleCreateTypeModalCancel}>
                        ยกเลิก
                    </Button>,
                    <Button
                        key="ok"
                        type="primary"
                        onClick={() => {
                            ConfirmationModal({
                                mode: 'create' as ActionType,
                                title: 'ประเภท Master Data',
                                onOk: () => handleCreateTypeModalOk(),
                                okType: 'default',
                            })
                        }}
                    >
                        ตกลง
                    </Button>,
                ]}
            >
                <Form
                    layout="vertical"
                    name="basic"
                    requiredMark={false}
                    form={form}
                    onFinish={hookSubmit}
                    autoComplete="off"
                >
                    <Form.Item
                        name={[MasterDataField.MASTERDATA_REFCODE]}
                        label="Ref. Code"
                        rules={[
                            { required: true, message: 'กรุณากรอก Ref. Code' },
                            {
                                pattern: /^[a-zA-Z\s]*$/,
                                message:
                                    'โปรดกรอก Ref. Code เป็นภาษาอังกฤษเท่านั้น',
                            },
                        ]}
                    >
                        <Input placeholder="กรุณากรอก Ref. Code Ex. example_refcode" />
                    </Form.Item>

                    <Form.Item
                        name={[MasterDataField.MASTERDATA_TYPENAME]}
                        label="ชื่อประเภท Master Data"
                        rules={[
                            {
                                required: true,
                                message: 'กรุณากรอกชื่อประเภท Master Data',
                            },
                        ]}
                    >
                        <Input placeholder="กรุณากรอกชื่อประเภท Master Data" />
                    </Form.Item>

                    <Form.Item
                        name={[MasterDataField.MASTERDATA_DESCRIPTION]}
                        label="คำอธิบาย"
                        rules={[
                            {
                                required: true,
                                message: 'กรุณากรอกชื่อประเภท Master Data',
                            },
                        ]}
                    >
                        <TextArea
                            placeholder="กรุณากรอกคำอธิบาย"
                            autoSize={{ minRows: 1, maxRows: 3 }}
                        />
                    </Form.Item>

                    <Form.Item
                        name={[MasterDataField.MASTERDATA_URL]}
                        label="รูปภาพ Banner"
                    >
                        <Checkbox onChange={onChangeCheck}>Banner</Checkbox>
                    </Form.Item>
                </Form>
            </Modal>
        </PageLayout>
    )
}

export default MasterDataPage
