import React from 'react'
import {
    Button,
    Flex,
    Form,
    FormInstance,
    FormListFieldData,
    FormListOperation,
    Input,
    Tooltip,
} from 'antd'
import { CloseOutlined, InfoCircleOutlined } from '@ant-design/icons'
import InputUploadFile from '../../input/inputUploadFile'

interface ChoiceItemFormProps {
    form: FormInstance
    field: FormListFieldData
    subFields: FormListFieldData[]
    subOpt: FormListOperation
}

const ChoiceItemForm: React.FC<ChoiceItemFormProps> = ({
    form,
    field,
    subFields,
    subOpt,
}) => {
    const watchValues = Form.useWatch([], form)

    return (
        <Flex vertical gap={16}>
            {subFields.map((subField: FormListFieldData) => {
                return (
                    <Flex vertical gap={24} key={subField.key}>
                        <Flex justify="space-between">
                            <Form.Item
                                name={[subField.name, 'file']}
                                style={{ marginBottom: '0px' }}
                                label={
                                    <div>
                                        อัปโหลดไฟล์{' '}
                                        <Tooltip title="ระบบรองรับแค่ไฟล์ PDF">
                                            <InfoCircleOutlined />
                                        </Tooltip>
                                    </div>
                                }
                                rules={[
                                    {
                                        required: true,
                                        message: 'กรุณากรอกอัพโหลดไฟล์',
                                    },
                                ]}
                            >
                                <InputUploadFile
                                    form={form}
                                    field={field}
                                    subField={subField}
                                    initialValues={
                                        watchValues?.productItem[field.key]
                                            ?.choiceItem[subField.key]?.file
                                    }
                                />
                            </Form.Item>
                            {subFields.length > 1 && (
                                <CloseOutlined
                                    onClick={() => {
                                        subOpt.remove(subField.name)
                                    }}
                                />
                            )}
                        </Flex>

                        <Form.Item
                            name={[subField.name, 'title']}
                            label="ชื่อไฟล์"
                            rules={[
                                {
                                    required: true,
                                    message: 'กรุณากรอกชื่อของไฟล์',
                                },
                            ]}
                        >
                            <Input placeholder="กรุณากรอกชื่อของไฟล์" />
                        </Form.Item>
                    </Flex>
                )
            })}
            <Button type="dashed" onClick={() => subOpt.add()} block>
                + เพิ่มไฟล์
            </Button>
        </Flex>
    )
}

export default ChoiceItemForm
