import { LoginResponse } from '../model/response/auth.response'
import { LoginRequest } from '../model/interface/loginForm'
import { prefixApi } from './prefix.api'
import { axiosInstance } from './axios.api'

export class AuthService {
    private static url = `${prefixApi.identity}/login`

    public static async login(
        credentials: LoginRequest
    ): Promise<LoginResponse> {
        const res = await axiosInstance.post<LoginResponse>(
            this.url,
            credentials
        )
        return res.data
    }
}
