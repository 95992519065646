/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react'
import { MenuOutlined } from '@ant-design/icons'
import { useSortable } from '@dnd-kit/sortable'
import { Table, Checkbox } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { Image } from 'antd'
import {
    IProductElement,
    ProductApiResponse,
} from '../../../../model/interface/product'

interface ProductListProps {
    dataSource: ProductApiResponse | undefined
    editable: boolean
    setFilterAllProduct: React.Dispatch<
        React.SetStateAction<{
            page: number
            pageSize: number
            search: string
            id: string
            pagination: boolean
        }>
    >
    checkbox: string[]
    setCheckBox: React.Dispatch<React.SetStateAction<string[]>>
    pagination?: boolean
}
interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
    'data-row-key': string
}

const Row = ({ children, ...props }: RowProps) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        setActivatorNodeRef,
        transform,
        transition,
        isDragging,
    } = useSortable({
        id: props['data-row-key'],
    })

    const style: React.CSSProperties = {
        ...props.style,
        transform: transform ? `scaleY(1) ${transform}` : undefined,
        transition,
        ...(isDragging ? { position: 'relative', zIndex: 100 } : {}),
    }

    return (
        <tr {...props} ref={setNodeRef} style={style} {...attributes}>
            {React.Children.map(children, (child) => {
                if ((child as React.ReactElement).key === 'sort') {
                    return React.cloneElement(child as React.ReactElement, {
                        children: (
                            <MenuOutlined
                                ref={setActivatorNodeRef}
                                style={{ touchAction: 'none', cursor: 'move' }}
                                {...listeners}
                            />
                        ),
                    })
                }
                return child
            })}
        </tr>
    )
}

export const ProductRecommentList = (props: ProductListProps): JSX.Element => {
    const { dataSource, editable, setFilterAllProduct, checkbox, setCheckBox } =
        props
    const [checkedCount, setCheckedCount] = useState(0)
    

    const renderCheckbox = (productId: string) => (
        <Checkbox
            disabled={
                !editable
                    ? true
                    : checkbox.includes(productId)
                      ? false
                      : !editable || checkbox.length === 2
            }
            checked={checkbox.includes(productId)}
            onChange={(e) => handleCheckboxChange(e.target.checked, productId)}
        />
    )

    const handleCheckboxChange = (checked: boolean, productId: string) => {
        if (checked) {
            if (
                checkedCount + checkbox.length <= 2 &&
                !checkbox.includes(productId)
            ) {
                setCheckBox((prev) => [...prev, productId])
                setCheckedCount((prev) => prev + 1)
            }
        } else {
            setCheckBox((prev) => prev.filter((id) => id !== productId))
            setCheckedCount((prev) => prev - 1)
        }
    }

    const columns: ColumnsType<IProductElement> = [
        {
            title: '',
            dataIndex: 'checkbox',
            width: '28px',
            render: (_: string, product: IProductElement) => {
                return <div>{renderCheckbox(product.id)}</div>
            },
        },

        {
            title: 'รูปภาพของสินค้า',
            dataIndex: 'bannerImg',
            width: '200px',
            render: (_, record) => (
                <Image
                    width={200}
                    style={{ aspectRatio: '16/9', objectFit: 'cover' }}
                    src={record.banner}
                />
            ),
        },
        {
            title: 'ชื่อสินค้า',
            dataIndex: 'title',
            width: '200px',
        },
        {
            title: 'หมวดหมู่ ',
            dataIndex: 'category',
            width: '150px',
            render: (_, record) => (
                <div style={{ lineBreak: 'auto', width: '100px' }}>
                    {record.category.name}
                </div>
            ),
        },
        {
            title: 'ราคา',
            dataIndex: 'number',
            width: '100px',
            render: (_, record) => (
                <div style={{ lineBreak: 'auto', width: '100px' }}>
                    {record.price.toLocaleString()}
                </div>
            ),
        },
      
        {
            title: 'เผยแพร่โดย',
            dataIndex: 'auther',
            width: '130px',
            render: (_, record) => (
                <div style={{ lineBreak: 'auto', width: '130px' }}>
                    {record.createBy.email || '-'}
                </div>
            ),
        },
    ]

    const handlePageChange = (page: number, pageSize: number) => {
        setFilterAllProduct(prevFilter => ({
            ...prevFilter,
            page,
            pageSize,
            search: prevFilter.search
        }));
    }

    return (
        <>
            <Table
                components={{
                    body: {
                        row: Row,
                    },
                }}
                rowKey="id"
                columns={columns}
                dataSource={dataSource?.data}
                pagination={{
                    current: dataSource?.page,
                    pageSize: dataSource?.pageSize,
                    total: dataSource?.total,
                    onChange: handlePageChange,
                }}
            />
        </>
    )
}
