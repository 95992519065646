import React from 'react'
import PageLayout from '../../../../components/page-layout'
import { Button, Space } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import useFormProduct from '../../components/productForm/useFormProduct'
import ProductForm from '../../components/productForm'

export const EditProduct: React.FC = () => {
    const { confirmCancelProduct, disabled } = useFormProduct()

    return (
        <PageLayout
            title="Product"
            action={
                <Space>
                    <Button
                        type="default"
                        onClick={() =>
                            confirmCancelProduct(<ExclamationCircleOutlined />)
                        }
                    >
                        ยกเลิก
                    </Button>
                    <Button
                        type="primary"
                        htmlType="submit"
                        form="product-form"
                        disabled={disabled}
                    >
                        บันทึก
                    </Button>
                </Space>
            }
        >
            <ProductForm />
        </PageLayout>
    )
}
