import React from 'react'
import { notification } from 'antd'

export type NotificationType = 'info' | 'warning' | 'error' | 'success'

const MESSAGE = {
    info: 'ประกาศ',
    warning: 'แจ้งเตือน',
    error: 'เกิดปัญหา',
    success: 'สำเร็จ',
}

interface INotificationMessageProps {
    type: NotificationType
    message: React.ReactNode
}

export const notificationMessage = ({
    type,
    message,
}: INotificationMessageProps): void => {
    notification[type]({
        message: MESSAGE[type],
        description: message,
        duration: 5,
    })
}
