export enum BannerField {
    // BANNER_ID = 'bannerId',
    // DESKTOP_BANNER = 'desktopBanner',
    // MOBILE_BANNER = 'mobileBanner',
    // BANNER_NAME = 'bannerName',
    // DEEPLINK = 'deeplink',
    // BANNER_DESCRIPTION = 'bannerDescription',
    ID = 'id',
    IMAGE_URL_DESKTOP = 'imageUrlDesktop',
    IMAGE_URL_MOBILE = 'imageUrlMobile',
    DEEPLINK = 'deepLink',
    IMAGE_NAME = 'imageName',
    IMAGE_DESCRIPTION = 'imageDescription',
    PRIORITY = 'priority',
    CREATE_DATE = 'createDate',
    UPDATE_DATE = 'updateDate',
    CREATE_BY = 'createBy',
}
