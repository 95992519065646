import React from 'react'
import { Form, FormInstance } from 'antd'
import { useNavigate, useParams } from 'react-router'
import { notificationMessage } from '../../../../components/notification'
import { ContentService } from '../../../../service/content.service'
import { ContentRequest } from '../../../../model/interface/request/contentRequest'
import ConfirmationModal from '../../../../components/confirmationModal'
import { useAuth } from '../../../../context/Profile.context'

interface useFormContentReturnType {
    form: FormInstance
    isCreate: boolean
    disabled: boolean
    initialValues?: ContentRequest
    confirmCreateEditContent: (isCreate: boolean, body: ContentRequest) => void
    confirmCancelContent: (isCreate: boolean) => void
}

function useFormContent(): useFormContentReturnType {
    const navigate = useNavigate()
    const auth = useAuth()
    const [form] = Form.useForm()
    const { id } = useParams()

    const isCreate = window.location.href.split('/').pop() === 'create'

    const [disabled, setDisabled] = React.useState(false)
    const [initialValues, setInitialValues] = React.useState<ContentRequest>()

    const fetchInitialValue = React.useCallback(async () => {
        if (id && !isCreate) {
            try {
                const initialValueResponse = await ContentService.getById(id)
                setInitialValues(initialValueResponse)
            } catch (error) {
                navigate('/content')
                notificationMessage({
                    type: 'error',
                    message: `ไม่สามารถดึงข้อมูล Content ID: ${id} ได้`,
                })
            }
        }
    }, [id, isCreate, navigate])
    React.useEffect(() => {
        fetchInitialValue()
    }, [fetchInitialValue])

    React.useEffect(() => {
        form.setFieldsValue(initialValues)
    }, [form, initialValues])

    const pushCreateContent = async (values: ContentRequest) => {
        try {
            await ContentService.createContent(values)
            notificationMessage({
                type: 'success',
                message: 'สร้างข้อมูลสำเร็จ',
            })
        } catch (error) {
            notificationMessage({
                type: 'error',
                message: 'สร้างข้อมูลไม่สำเร็จ',
            })
            console.error('Error occurred while posting content:', error)
        } finally {
            navigate('/content')
        }
    }

    const putEditContent = async (id: string, values: ContentRequest) => {
        try {
            await ContentService.editContent(id, values)
            notificationMessage({
                type: 'success',
                message: 'แก้ไขข้อมูลสำเร็จ',
            })
        } catch (error) {
            notificationMessage({
                type: 'error',
                message: 'แก้ไขข้อมูลไม่สำเร็จ',
            })
            console.error('Error occurred while puting content:', error)
        } finally {
            navigate('/content')
        }
    }

    const handleCreateEditContent = async (
        isCreate: boolean,
        data: ContentRequest
    ) => {
        const profile = auth.profile || { email: '', userId: '' }
        const body: ContentRequest = {
            ...data,
            createBy: {
                email: profile.email,
                id: profile.userId,
            },
        }
        try {
            setDisabled(true)
            if (isCreate) {
                await pushCreateContent(body)
            } else {
                if (id) {
                    await putEditContent(id, body)
                }
            }
        } catch (error) {
            console.error(
                `Error ${isCreate ? 'create' : 'edit'} content:`,
                error
            )
        }
    }

    const handleCancelContent = () => {
        navigate('/content')
        notificationMessage({
            type: 'success',
            message: 'ยกเลิกสำเร็จ',
        })
    }

    const confirmCreateEditContent = (
        isCreate: boolean,
        body: ContentRequest
    ) => {
        ConfirmationModal({
            mode: isCreate ? 'create' : 'update',
            title: 'content',
            onOk: () => handleCreateEditContent(isCreate, body),
        })
    }

    const confirmCancelContent = (isCreate: boolean) => {
        ConfirmationModal({
            mode: isCreate ? 'cancelCreate' : 'cancelUpdate',
            title: 'content',
            onOk: () => handleCancelContent(),
        })
    }

    return {
        form,
        isCreate,
        disabled,
        initialValues,
        confirmCreateEditContent,
        confirmCancelContent,
    }
}

export default useFormContent
