import { Result, Button } from 'antd'
import React from 'react'
import { useNavigate } from 'react-router-dom'

const AdminLoginFail = (): React.ReactElement => {
    const navigate = useNavigate()

    const onClickOk = () => {
        navigate('/auth')
    }

    return (
        <Result
            status="403"
            title={<h1>403</h1>}
            subTitle="ขออภัย คุณไม่ได้รับอนุญาตให้เข้าถึงหน้านี้"
            extra={
                <Button type="primary" onClick={onClickOk}>
                    กลับไปหน้าเข้าสู่ระบบ
                </Button>
            }
        />
    )
}

export default AdminLoginFail
