import React from 'react'
import { FormInstance, Upload, UploadFile, UploadProps, message } from 'antd'
import { InboxOutlined } from '@ant-design/icons'
import { UploadChangeParam } from 'antd/lib/upload'
import styled from 'styled-components'

const { Dragger } = Upload

interface InputDragUploadImgProps {
    form: FormInstance
    name: string | (string | number)[]
    initialValue?: string
    aspectRatioRecomment: string
    sizeRecomment: string
}

const DraggerInput = styled(Dragger)`
    span.ant-upload.ant-upload-btn {
        padding: 0;
    }
    div.ant-upload-drag-container {
        width: 100%;
        height: 222px;
    }
`

const InputDragUploadImg: React.FC<InputDragUploadImgProps> = ({
    form,
    name,
    initialValue,
    aspectRatioRecomment,
}) => {
    const [imageUrl, setImageUrl] = React.useState<string>('')
    const [fileList, setFileList] = React.useState<UploadFile[]>([])

    React.useEffect(() => {
        if (initialValue) {
            const urlSegments = initialValue.split('/')
            const lastSegment = urlSegments[urlSegments.length - 1]
            const fileName = lastSegment.split('?')[0]
            setImageUrl(initialValue)
            setFileList([
                {
                    url: initialValue,
                    uid: '',
                    name: fileName,
                },
            ])
        }
    }, [form, initialValue, name])

    const uploadProps: UploadProps = {
        name: 'image',
        multiple: false,
        accept: 'image/jpg, image/png, image/jpeg',
        action: `${process.env.REACT_APP_API_URL_UAT}/api/admin/upload`,
        headers: {
            Authorization: `Bearer ${window.localStorage.getItem(
                'access_token'
            )}`,
        },
        maxCount: 1,
        fileList: fileList,
        onChange: (info: UploadChangeParam) => {
            const { status, response } = info?.file
            if (status === 'done') {
                const { url } = response
                setImageUrl(url)
                message.success(`${info.file.name} อัปโหลดไฟล์เสร็จสิ้น`)
                form.setFieldValue(name, url)
            } else if (status === 'error') {
                const { url } = response
                message.error(`${info.file.name} อัปโหลดไฟล์ล้มเหลว`)
                setImageUrl(url)
            }
            setFileList(info.fileList)
        },
        onPreview: async (file: UploadFile) => {
            window.open(file.url || file.response.url, '_blank')
        },
        onRemove: () => {
            form.setFieldValue(name, undefined)
        },
    }

    return (
        <DraggerInput maxCount={1} {...uploadProps}>
            {fileList.length === 0 ? (
                <div style={{ padding: 16 }}>
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">เลือกไฟล์หรือลากมาที่นี่</p>
                    <p className="ant-upload-hint">
                        อัตราส่วนที่แนะนำคือ {`${aspectRatioRecomment} `}
                        <br />
                        รองรับการอัปโหลดไฟล์ PNG, JPG, JPEG ครั้งละ 1
                        รูปภาพเท่านั้น
                    </p>
                </div>
            ) : (
                <img
                    src={imageUrl}
                    alt="Preview"
                    style={{
                        // width: `${imageWidth}px`,
                        height: '216px',
                        margin: '12px',
                        // objectFit: 'fill',
                    }}
                />
            )}
        </DraggerInput>
    )
}

export default InputDragUploadImg
