import { AxiosResponse } from 'axios'
import { IAdmin, IAdminForm, IAdminRes } from '../model/interface/admin'
import { prefixApi } from './prefix.api'
import { axiosInstance } from './axios.api'

export class AdminService {
    private static url = `${prefixApi.admin}`

    public static async getAllAdmin(): Promise<IAdminRes> {
        const res: AxiosResponse<IAdminRes> =
            await axiosInstance.get<IAdminRes>(`datas`)

        return res.data
    }

    public static async getAdmin(id: string): Promise<IAdmin> {
        const res: AxiosResponse<IAdmin> = await axiosInstance.get<IAdmin>(
            `by-id/${id}`
        )

        return res.data
    }

    public static async createAdmin(req: IAdminForm): Promise<IAdminForm> {
        const res = await axiosInstance.post<IAdminForm>(`data`, req)

        return res.data
    }

    public static async updateAdmin(
        id: string,
        req: IAdminForm
    ): Promise<IAdminForm> {
        const res: AxiosResponse<IAdminForm> =
            await axiosInstance.put<IAdminForm>(`${id}`, req)

        return res.data
    }

    public static async deleteAdmin(id: string): Promise<IAdminRes> {
        const res: AxiosResponse<IAdminRes> =
            await axiosInstance.delete<IAdminRes>(`${id}`)

        return res.data
    }
}
