import React, { useState } from 'react'
import PageLayout from '../../../components/page-layout'
import { Button, Form, Space } from 'antd'
import { useNavigate } from 'react-router'
import { BannerForm } from '../components/bannerForm'
import { IBannerForm } from '../../../model/interface/bannerForm'
import { BannerService } from '../../../service/banner.service'
import { useAuth } from '../../../context/Profile.context'
import { notificationMessage } from '../../../components/notification'
import ConfirmationModal, {
    ActionType,
} from '../../../components/confirmationModal'

export const CreateBanner = (): JSX.Element => {
    const auth = useAuth()
    const navigate = useNavigate()
    const [form] = Form.useForm()
    const [disabled, setDisabled] = useState(false)

    const handleCreate = async (req: IBannerForm) => {
        try {
            await BannerService.createBanner(req).then(() => {
                notificationMessage({
                    type: 'success',
                    message: 'สร้าง Banner สำเร็จ',
                })
            })
        } catch (error) {
            notificationMessage({
                type: 'error',
                message: 'สร้าง Banner ไม่สำเร็จ',
            })
            console.error('Error create banner:', error)
        } finally {
            navigate(`/banner`)
        }
    }

    const showCreateConfirm = (req: IBannerForm) => {
        ConfirmationModal({
            mode: 'create' as ActionType,
            title: 'Banner',
            onOk: () => handleCreate(req),
            onCancel: () => setDisabled(false),
        })
    }

    const showCancelConfirm = () => {
        ConfirmationModal({
            mode: 'cancelCreate' as ActionType,
            title: 'Banner',
            onOk: () => navigate('/banner'),
        })
    }

    const onSubmitForm = async (formData: IBannerForm) => {
        const profile = auth.profile
        setDisabled(true)
        if (profile) {
            const { email, userId } = profile
            const updatedFormData = {
                ...formData,
                createBy: { email, id: userId },
            }
            showCreateConfirm(updatedFormData)
        }
    }

    return (
        <>
            <PageLayout
                title="สร้าง Banner"
                action={
                    <Space>
                        <Button
                            type="default"
                            onClick={() => showCancelConfirm()}
                            disabled={disabled}
                        >
                            ยกเลิก
                        </Button>
                        <Button
                            type="primary"
                            disabled={disabled}
                            onClick={() => form.submit()}
                        >
                            บันทึก
                        </Button>
                    </Space>
                }
            >
                <div>
                    <BannerForm form={form} onSubmit={onSubmitForm} />
                </div>
            </PageLayout>
        </>
    )
}
