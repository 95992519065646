import { AxiosResponse } from 'axios'
import { prefixApi } from './prefix.api'
import { axiosInstance } from './axios.api'
import { ICustomerRes } from '../model/interface/customer'

export class CustomerService {
    private static url = `${prefixApi.customer}`

    public static async getAllCustomer(): Promise<ICustomerRes> {
        const res: AxiosResponse<ICustomerRes> =
            await axiosInstance.get<ICustomerRes>(`${this.url}s`)

        return res.data
    }
}
