import React from 'react'
import PageLayout from '../../components/page-layout'
import { PlusOutlined } from '@ant-design/icons'
import { Button, Space } from 'antd'
import { useNavigate } from 'react-router'
import { RoutePath } from '../../model/enum/route-path'
import { BlogList } from './components/BlogList'

const BlogPage = (): JSX.Element => {
    const navigate = useNavigate()

    return (
        <PageLayout
            title="ระบบจัดการบทความ"
            action={
                <Space>
                    <Button
                        onClick={() => navigate(`/${RoutePath.BLOG}/create`)}
                        type="primary"
                    >
                        <PlusOutlined />
                        สร้างบทความ
                    </Button>
                </Space>
            }
        >
            <BlogList />
        </PageLayout>
    )
}

export default BlogPage
