import React, { useEffect, useState } from 'react'
import { Empty, Input, Table, TableColumnsType } from 'antd'
import { CheckCircleOutlined } from '@ant-design/icons'
import {
    MasterDataType,
    MasterDataTypeResponse,
} from '../../../model/interface/masterData'
import { MasterDataService } from '../../../service/masterData.service'
import debounce from 'lodash/debounce'

const { Search } = Input
export interface DataTypeMaster {
    key: string
    refCode: string
    name: string
    description: string
    banner: boolean
}

export const mapData = (
    dataResponse: MasterDataType[]
): DataTypeMaster[] | null => {
    if (dataResponse == null) {
        return null
    }
    return dataResponse.map((item) => ({
        key: item.id,
        refCode: item.refCode,
        name: item.typeName,
        description: item.description,
        banner: item.banner,
    }))
}

const columns: TableColumnsType<DataTypeMaster> = [
    {
        title: 'Ref. Code.',
        dataIndex: 'refCode',
        key: 'refCode',
        width: '30%',
    },
    {
        title: 'ชื่อ',
        dataIndex: 'name',
        key: 'name',
        width: '30%',
        // sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
        title: 'คำอธิบาย',
        dataIndex: 'description',
        key: 'description',
        width: '20%',
    },
    {
        title: 'รูป Banner',
        dataIndex: 'banner',
        key: 'banner',
        width: '20%',
        align: 'center',
        render: (banner: boolean) =>
            banner ? <CheckCircleOutlined style={{ color: 'green' }} /> : '-',
    },
]

const TypeMasterData = (): JSX.Element => {
    const [searchValue, setSearchValue] = useState('')
    const [data, setData] = useState<DataTypeMaster[] | null>([])

    const fetchData = async () => {
        try {
            const responseData: MasterDataTypeResponse =
                searchValue == ''
                    ? await MasterDataService.getAllMasterDataType()
                    : await MasterDataService.getAllMasterDataType({
                          search: searchValue,
                      })
            const mappedData: DataTypeMaster[] | null = mapData(
                responseData.data
            )
            setData(mappedData)
        } catch (error) {
            console.error('Error fetching data:', error)
        }
    }

    useEffect(() => {
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchValue])

    const handleSearch = debounce((value: string) => {
        setSearchValue(value)
    }, 300)

    return (
        <>
            <Search
                placeholder="ค้นหาด้วยชื่อประเภทของ Master Data"
                style={{ marginBottom: '20px' }}
                onChange={(e) => handleSearch(e.target.value)}
                enterButton
                allowClear
            />
            <Table
                style={{ marginTop: '16px' }}
                columns={columns}
                dataSource={data !== null ? data : undefined}
                pagination={false}
                locale={
                    data !== null
                        ? {}
                        : {
                              emptyText: (
                                  <Empty
                                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                                      description="ไม่มีข้อมูล"
                                  />
                              ),
                          }
                }
            />
        </>
    )
}

export default TypeMasterData
