import { LogoutOutlined, UserOutlined } from '@ant-design/icons'
import { Avatar, Tooltip } from 'antd'
import React from 'react'
import { ColorsConstant } from '../../../assets/colors/Colors'
import { UserProfile } from '../../../context/Profile.context'

interface UserProfileProps {
    collapsed: boolean
    profile: UserProfile | null
    onLogout: () => void
}

const UserProfileComponent: React.FC<UserProfileProps> = ({
    collapsed,
    profile,
    onLogout,
}) => {
    return (
        <div className="user-profile">
            <div className={`user-container`}>
                <Avatar
                    style={{
                        backgroundColor: ColorsConstant.primary,
                        minWidth: 32,
                    }}
                    icon={<UserOutlined />}
                    data-testid="user-avatar"
                />
                {!collapsed && (
                    <>
                        <div className="user-info">
                            <div className="user-name">
                                {profile?.displayName}
                            </div>
                            <div className="user-role">
                                {profile?.role || 'Dev Admin'}
                            </div>
                        </div>
                        <Tooltip title="ออกจากระบบ">
                            <div
                                key="logout"
                                onClick={onLogout}
                                className="logout-button"
                            >
                                <LogoutOutlined />
                            </div>
                        </Tooltip>
                    </>
                )}
            </div>
        </div>
    )
}

export default UserProfileComponent
