import { axiosInstance } from './axios.api'
import {
    PostProductApiResponse,
    ProductAnotherRequest,
    ProductDeleteResponse,
    ProductRequest,
} from '../model/interface/product'
import {
    IProductElement,
    ProductApiResponse,
    ProductRecRequest,
} from '../model/interface/product'
import { AxiosResponse } from 'axios'
import { prefixApi } from './prefix.api'
import { HttpResponse } from '../model/response/httpResponse'

export class ProductService {
    private static url = `${prefixApi.product}`

    public static async putProduct(
        id: string,
        body: ProductRequest
    ): Promise<HttpResponse> {
        const res = await axiosInstance.put(`${this.url}/${id}`, body)
        return res.data
    }

    public static async postProduct(
        body: ProductRequest
    ): Promise<HttpResponse> {
        const res = await axiosInstance.post<HttpResponse>(
            `${this.url}`,
            body
        )
        return res.data
    }

    public static async postProductRecomment(
        body: ProductRecRequest
    ): Promise<ProductApiResponse> {
        const res = await axiosInstance.post<ProductApiResponse>(
            `${this.url}/recommendation`,
            body
        )
        return res.data
    }

    public static async postAnotherProduct(
        body: ProductAnotherRequest
    ): Promise<ProductApiResponse> {
        const res = await axiosInstance.post<ProductApiResponse>(
            `${this.url}/another`,
            body
        )
        return res.data
    }

    public static async getAllProducts(): Promise<PostProductApiResponse> {
        const res = await axiosInstance.get<PostProductApiResponse>(
            `${this.url}s`
        )
        return res.data
    }

    public static async getAllProduct(
        page?: number,
        pageSize?: number,
        search?: string,
        id?: string,
        pagination: boolean = true
    ): Promise<ProductApiResponse> {
        let res: AxiosResponse<ProductApiResponse>

        let url = `${this.url}s?page=${page}&pageSize=${pageSize}`

        try {
            if (search !== undefined && id !== undefined) {
                url += `&search=${search}&masterDataItemId=${id}`
            } else if (search !== undefined) {
                url += `&search=${search}`
            } else if (id !== undefined) {
                url += `&masterDataItemId=${id}`
            }

            if (!pagination) {
                url += `&pagination=false`
            }

            res = await axiosInstance.get<ProductApiResponse>(url)

            return res.data
        } catch (error) {
            throw new Error(`Error fetching products: ${error}`)
        }
    }

    public static async getProductRecommendation(): Promise<ProductApiResponse> {
        const res = await axiosInstance.get<ProductApiResponse>(
            `${this.url}/recommendation`
        )
        return res.data
    }
    public static async getAnotherProduct(
        categoryID: string
    ): Promise<ProductApiResponse> {
        const res = await axiosInstance.get<ProductApiResponse>(
            `${this.url}/another?categoryId=${categoryID}`
        )
        return res.data
    }

    public static async getMasterDataProduct(
        masterDataItemId: string,
        page: number,
        pageSize: number,
        search?: string,
        pagination: boolean = true
    ): Promise<ProductApiResponse> {
        let url = `${this.url}s?masterDataItemId=${masterDataItemId}&page=${page}&pageSize=${pageSize}`

        if (search) {
            url += `&search=${search}`
        }

        if (!pagination) {
            url += `&pagination=${pagination}`
        }
        const res = await axiosInstance.get<ProductApiResponse>(url)
        return res.data
    }

    public static async getProductById(id: string): Promise<IProductElement> {
        const res = await axiosInstance.get<IProductElement>(
            `${this.url}/${id}`
        )
        return res.data
    }

    public static async deleteProduct(
        id: string
    ): Promise<ProductDeleteResponse> {
        const res = await axiosInstance.delete<ProductDeleteResponse>(
            `${this.url}/${id}`
        )
        return res.data
    }
}
