export enum RoutePath {
    AUTH = 'auth',
    LOGIN = 'login',
    HOME = 'home',
    BANNER = 'banner',
    PRODUCT = 'product',
    BLOG = 'blog',
    MASTER = 'masterdata',
    CREATE_ACCOUNT = 'create_account',
    CONTENT = 'content',
    LOGOUT = 'logout',
    FORBIDDEN = 'forbidden',
    HEALTH = 'healeh',
    ADMIN = 'admin',
    PAYMENT = 'payment',
}
