import React from 'react'
import { Form, FormInstance } from 'antd'
import { useNavigate, useParams } from 'react-router'
import { notificationMessage } from '../../../../components/notification'
import ConfirmationModal from '../../../../components/confirmationModal'
import { useAuth } from '../../../../context/Profile.context'
import { Blog } from '../../../../model/interface/blog'
import { BlogService } from '../../../../service/blog.service'

interface useBlogFormReturnType {
    form: FormInstance
    isCreate: boolean
    disabled: boolean
    initialValues?: Blog
    confirmCreateEditBlog: (isCreate: boolean, body: Blog) => void
    confirmCancelBlog: (isCreate: boolean) => void
}

function useBlogForm(): useBlogFormReturnType {
    const navigate = useNavigate()
    const auth = useAuth()
    const [form] = Form.useForm()
    const { id } = useParams()

    const isCreate = window.location.href.split('/').pop() === 'create'

    const [disabled, setDisabled] = React.useState(false)
    const [initialValues, setInitialValues] = React.useState<Blog>()

    const fetchInitialValue = React.useCallback(async () => {
        if (id && !isCreate) {
            try {
                const initialValueResponse = await BlogService.getBlog(id)
                setInitialValues(initialValueResponse)
            } catch (error) {
                navigate('/blog')
                notificationMessage({
                    type: 'error',
                    message: `ไม่สามารถดึงข้อมูลบทความ ID: ${id} ได้`,
                })
            }
        }
    }, [id, isCreate, navigate])
    React.useEffect(() => {
        fetchInitialValue()
    }, [fetchInitialValue])

    React.useEffect(() => {
        form.setFieldsValue(initialValues)
    }, [form, initialValues])

    const pushCreateBlog = async (values: Blog) => {
        try {
            await BlogService.createBlog(values)
            notificationMessage({
                type: 'success',
                message: 'สร้างข้อมูลสำเร็จ',
            })
        } catch (error) {
            notificationMessage({
                type: 'error',
                message: 'สร้างข้อมูลไม่สำเร็จ',
            })
            console.error('Error occurred while posting blog:', error)
        } finally {
            navigate('/blog')
        }
    }

    const putEditBlog = async (id: string, values: Blog) => {
        try {
            await BlogService.updateBlog(id, values)
            notificationMessage({
                type: 'success',
                message: 'แก้ไขข้อมูลสำเร็จ',
            })
        } catch (error) {
            notificationMessage({
                type: 'error',
                message: 'แก้ไขข้อมูลไม่สำเร็จ',
            })
            console.error('Error occurred while puting blog:', error)
        } finally {
            navigate('/blog')
        }
    }

    const handleCreateEditBlog = async (isCreate: boolean, data: Blog) => {
        const profile = auth.profile || { email: '', userId: '' }
        const body: Blog = {
            ...data,
            createBy: {
                email: profile.email,
                id: profile.userId,
            },
        }
        try {
            setDisabled(true)
            if (isCreate) {
                await pushCreateBlog(body)
            } else {
                if (id) {
                    await putEditBlog(id, body)
                }
            }
        } catch (error) {
            console.error(`Error ${isCreate ? 'create' : 'edit'} blog:`, error)
        }
    }

    const handleCancelblog = () => {
        navigate('/blog')
        notificationMessage({
            type: 'success',
            message: 'ยกเลิกสำเร็จ',
        })
    }

    const confirmCreateEditBlog = (isCreate: boolean, body: Blog) => {
        ConfirmationModal({
            mode: isCreate ? 'create' : 'update',
            title: 'blog',
            onOk: () => handleCreateEditBlog(isCreate, body),
        })
    }

    const confirmCancelBlog = (isCreate: boolean) => {
        ConfirmationModal({
            mode: isCreate ? 'cancelCreate' : 'cancelUpdate',
            title: 'blog',
            onOk: () => handleCancelblog(),
        })
    }

    return {
        form,
        isCreate,
        disabled,
        initialValues,
        confirmCreateEditBlog,
        confirmCancelBlog,
    }
}

export default useBlogForm
