import React, { useEffect, useState } from 'react'
import { ProductRecommentList } from './list'
import {
    IProductElement,
    ProductApiResponse,
} from '../../../model/interface/product'
import { ProductRecommentSort } from './sort'
import { ProductService } from '../../../service/product.service'
import { Input, Flex, Select } from 'antd'
import { debounce } from 'lodash'
import { MasterDataService } from '../../../service/masterData.service'

interface ProductReccommentProps {
    isEditing: boolean
    setUpdataChecked: React.Dispatch<React.SetStateAction<string[]>>
    onClickCancelRec: boolean
    isToggleRec: boolean
    isDisabledRec: (disabled: boolean) => void 
}

const ProductReccomment = ({
    isEditing,
    setUpdataChecked,
    onClickCancelRec,
    isToggleRec,
    isDisabledRec
}: ProductReccommentProps): JSX.Element => {
    const [dataSourceAllProduct, setDataSourceAllProduct] =
        useState<ProductApiResponse>()
    const [filteredDataSource, setFilteredDataSource] = useState<
        IProductElement[]
    >([])
    const [filterAllProduct, setFilterAllProduct] = useState({
        page: 1,
        pageSize: 10,
        search: '',
        id: '',
        pagination: false,
    })
    const [option, setOption] = useState<{ label: string; value: string }[]>()
    const [checkbox, setCheckBox] = useState<string[]>([])

    useEffect(() => {
        const fetchData = async () => {
            try {
                const product = await ProductService.getAllProduct(
                    filterAllProduct.page,
                    filterAllProduct.pageSize,
                    filterAllProduct.search,
                    filterAllProduct.id,
                    filterAllProduct.pagination
                )
                setDataSourceAllProduct(product)
                isDisabledRec(!product || product.data.length === 0)
            } catch (error) {
                console.error('Error fetching data:', error)
                isDisabledRec(true)
            }
        }

        fetchData()
    }, [filterAllProduct])
    
    useEffect(() => {
        const fetchPriority = async () => {
            try {
                const priorityData =
                    await ProductService.getProductRecommendation()
                const checkboxInitial: IProductElement[] = priorityData.data


                if (!onClickCancelRec) {
                    setFilteredDataSource(priorityData.data)
                    const newCheckBox: string[] = []
                    priorityData.data.forEach((data) => {
                        if (!checkbox.some((product) => product === data.id)) {
                            newCheckBox.push(data.id)
                        }
                    })
                    setCheckBox(newCheckBox)
                } else {
                    const initialIds = checkboxInitial.map((item) => item.id)
                    setFilteredDataSource(checkboxInitial)

                    const newCheckBox: string[] = []
                    priorityData.data.forEach((data) => {
                        if (!initialIds.includes(data.id)) {
                            newCheckBox.push(data.id)
                        }
                    })
                    const updatedCheckBox = initialIds.concat(newCheckBox)
                    setCheckBox(updatedCheckBox)
                }
            } catch (error) {
                console.error('Error fetching data:', error)
            }
        }

        const fetchCategoty = async () => {
            const categoryData =
                await MasterDataService.getMasterDataProductCategory()
            setOption(
                categoryData.data.map((item) => ({
                    label: item.masterDataName,
                    value: item.id,
                }))
            )
        }
        fetchCategoty()
        fetchPriority()
    }, [isToggleRec])

    const { Search } = Input

    const onSearch = debounce((value: string) => {
        setFilterAllProduct((prev) => {
            return { ...prev, search: value }
        })
    }, 500)

    const onSelected = (value: string) => {
        setFilterAllProduct((prev) => {
            return { ...prev, id: value }
        })
    }

    const filterOption = (
        input: string,
        option?: { label: string; value: string }
    ) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())

    const handleChange = (e: { target: { value: string } }) => {
        const value = e.target.value
        onSearch(value)
    }

    useEffect(() => {
        const filteredData = dataSourceAllProduct?.data.filter((product) =>
            checkbox.includes(product.id)
        )

        const sortedData = checkbox
            .map((id) => filteredData?.find((product) => product?.id === id))
            .filter(Boolean)

        const validSortedData = sortedData.filter(
            (product) => product !== undefined
        ) as IProductElement[]

        setFilteredDataSource(validSortedData)
        setUpdataChecked(validSortedData.map((product) => product.id))
    }, [checkbox, dataSourceAllProduct])

    return (
        <>
            <Flex gap={12}>
                <Select
                    style={{ width: '100%' }}
                    showSearch
                    placeholder="หมวดหมู่"
                    optionFilterProp="children"
                    onChange={onSelected}
                    filterOption={filterOption}
                    options={option}
                    allowClear
                />
                <Search
                    placeholder="ค้นหาชื่อสินค้า"
                    onChange={handleChange}
                    enterButton
                />
            </Flex>
            <h3>สินค้าแนะนำ</h3>
            <ProductRecommentList
                dataSource={dataSourceAllProduct}
                editable={isEditing}
                setFilterAllProduct={setFilterAllProduct}
                checkbox={checkbox}
                setCheckBox={setCheckBox}
            />
            <h3 style={{ display: 'flex', justifyContent: 'center' }}>
                ลำดับความสำคัญของสินค้าแนะนำ
            </h3>
            <ProductRecommentSort
                dataSource={filteredDataSource}
                setDataSource={setFilteredDataSource}
                editable={isEditing}
                setUpdataChecked={setUpdataChecked}
            />
        </>
    )
}

export default ProductReccomment
