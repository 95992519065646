import React, { useState } from 'react'
import { Button, Form, Space } from 'antd'
import { useNavigate } from 'react-router'
import { AdminService } from '../../../service/admin.service'
import { IAdminForm } from '../../../model/interface/admin'
import { notificationMessage } from '../../../components/notification'
import ConfirmationModal, {
    ActionType,
} from '../../../components/confirmationModal'
import PageLayout from '../../../components/page-layout'
import { AdminForm } from '../components/adminForm'

export const CreateAdmin = (): JSX.Element => {
    const navigate = useNavigate()
    const [form] = Form.useForm()
    const [disabled, setDisabled] = useState(false)

    const handleCreate = async (req: IAdminForm) => {
        try {
            await AdminService.createAdmin(req).then(() => {
                notificationMessage({
                    type: 'success',
                    message: 'เพิ่ม Admin สำเร็จ',
                })
            })
        } catch (error) {
            notificationMessage({
                type: 'error',
                message: 'เพิ่ม Admin ไม่สำเร็จ',
            })
            console.error('Error create banner:', error)
        } finally {
            navigate(`/admin`)
        }
    }

    const showCreateConfirm = (req: IAdminForm) => {
        ConfirmationModal({
            mode: 'create' as ActionType,
            title: 'Admin',
            onOk: () => handleCreate(req),
            onCancel: () => setDisabled(false),
        })
    }

    const showCancelConfirm = () => {
        ConfirmationModal({
            mode: 'cancelCreate' as ActionType,
            title: 'Admin',
            onOk: () => navigate('/admin'),
        })
    }

    const onSubmitForm = async (
        formData: Omit<IAdminForm, 'confirmPassword'>
    ) => {
        setDisabled(true)
        showCreateConfirm(formData)
    }

    return (
        <>
            <PageLayout
                title="สร้าง Admin"
                action={
                    <Space>
                        <Button
                            type="default"
                            onClick={() => showCancelConfirm()}
                            disabled={disabled}
                        >
                            ยกเลิก
                        </Button>
                        <Button
                            type="primary"
                            disabled={disabled}
                            onClick={() => form.submit()}
                        >
                            บันทึก
                        </Button>
                    </Space>
                }
            >
                <div>
                    <AdminForm
                        form={form}
                        onSubmit={onSubmitForm}
                        mode="create"
                    />
                </div>
            </PageLayout>
        </>
    )
}
