import React from 'react'
import PageLayout from '../../../components/page-layout'
import { Button, Space } from 'antd'
import ContentForm from '../components/contentForm'
import useFormContent from '../components/contentForm/useFormContent'

const EditContentPage: React.FC = () => {
    const { confirmCancelContent, disabled, isCreate } = useFormContent()

    return (
        <PageLayout
            title="ระบบจัดการ Content"
            action={
                <Space>
                    <Button
                        type="default"
                        disabled={disabled}
                        onClick={() => confirmCancelContent(isCreate)}
                    >
                        ยกเลิก
                    </Button>
                    <Button
                        type="primary"
                        htmlType="submit"
                        form="content-form"
                        disabled={disabled}
                    >
                        บันทึก
                    </Button>
                </Space>
            }
        >
            <ContentForm />
        </PageLayout>
    )
}

export default EditContentPage
