import React from 'react'
import './App.css'
import { ConfigProvider } from 'antd'
import ApplicationRoute from './application.route'
import Div100vh from 'react-div-100vh'
import { ColorsConstant, Text_base } from './assets/colors/Colors'
import { AuthProvider } from './contexts/AuthContext'
import { AppProvider } from './contexts/AppContext'
import locale from 'antd/lib/locale/th_TH'

export const important_component = {
    // colorPrimary: ColorsConstant.primary,
    colorBgBase: ColorsConstant.secondary,
    colorError: ColorsConstant.danger,
    colorInfo: ColorsConstant.info,
    colorLink: ColorsConstant.info,
    colorSuccess: ColorsConstant.success,
    colorTextBase: ColorsConstant.navbar_color_text,
    colorWarning: ColorsConstant.warning,
    colorBorder: ColorsConstant.color_border,
    fontFamily: Text_base.fontFamily,
    fontSize: Text_base.fontSize,
    itemSelectedColor: ColorsConstant.navbar_color_text,
    // colorPrimaryHover: ColorsConstant.color_primary_hover,
}

const App = (): JSX.Element => {
    return (
        <ConfigProvider
            theme={{
                token: important_component,
            }}
            locale={locale}
        >
            <AuthProvider>
                <AppProvider>
                    <Div100vh>
                        <ApplicationRoute />
                    </Div100vh>
                </AppProvider>
            </AuthProvider>
        </ConfigProvider>
    )
}

export default App
