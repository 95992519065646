import React, { useEffect, useState } from 'react'
import { InboxOutlined } from '@ant-design/icons'
import { UploadProps, message, Upload, UploadFile } from 'antd'

const { Dragger } = Upload

export const UploadImg = ({
    onUploadImgSuccess,
    initialValue,
    onUploadImgDelete,
}: {
    onUploadImgSuccess: (url: string, id: string) => void
    initialValue: string | undefined
    onUploadImgDelete: () => void
}): JSX.Element => {
    const [imageUrl, setImageUrl] = useState<string>('')
    // const [imageID , setImageID] = useState<string>('')
    const [previewImageContent, setPreviewImageContent] = useState<
        UploadFile[]
    >([])

    useEffect(() => {
        if (initialValue) {
            const urlSegments = initialValue.split('/')
            const lastSegment = urlSegments[urlSegments.length - 1]
            const fileName = lastSegment.split('?')[0]
            setImageUrl(initialValue)
            setPreviewImageContent([
                {
                    url: initialValue,
                    uid: '',
                    name: fileName,
                },
            ])
        }
    }, [initialValue])

    const uploadProps: UploadProps = {
        name: 'image',
        multiple: false,
        accept: 'image/jpg, image/png, image/jpeg',
        maxCount: 1,
        action: `${process.env.REACT_APP_API_URL_UAT}/api/admin/upload`,
        headers: {
            Authorization: `Bearer ${window.localStorage.getItem(
                'access_token'
            )}`,
            accept: 'application/json',
        },
        fileList: previewImageContent,
        onChange(info) {
            const { status, response } = info?.file
            if (status === 'done') {
                const { url, id } = response
                setImageUrl(url)
                // setImageID(id)
                message.success(`${info.file.name} อัปโหลดไฟล์เสร็จสิ้น`)
                onUploadImgSuccess(url, id)
            } else if (status === 'error') {
                const { url } = response
                message.error(`${info.file.name} อัปโหลดไฟล์ล้มเหลว`)
                setImageUrl(url)
            } else if (status === 'removed') {
                // onUploadImgDelete(imageID, imageUrl)
                setImageUrl('')
                onUploadImgDelete()
            }
            setPreviewImageContent(info.fileList)
        },
        onPreview: async (file: UploadFile) => {
            window.open(file.url || file.response.url, '_blank')
        },
        onRemove: () => {
            onUploadImgDelete()
            return true
        },
    }

    return (
        <>
            <Dragger {...uploadProps}>
                {previewImageContent.length === 0 && (
                    <>
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">
                            เลือกไฟล์หรือลากมาที่นี่
                        </p>
                        <p className="ant-upload-hint">
                            อัตราส่วนที่แนะนำคือ 16:9
                            <br />
                            รองรับการอัปโหลดไฟล์ PNG, JPG, JPEG ครั้งละ 1
                            รูปภาพเท่านั้น
                        </p>
                    </>
                )}
                {previewImageContent.length > 0 && (
                    <img
                        src={imageUrl}
                        alt="Preview"
                        style={{ width: '507px', height: '285px' }}
                    />
                )}
            </Dragger>
        </>
    )
}
