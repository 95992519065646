import React, { useEffect, useState } from 'react'
import PageLayout from '../../../components/page-layout'
import { Button, Form, Space } from 'antd'
import { useNavigate, useParams } from 'react-router'
import { MasterDataForm } from '../components/masterDataForm'
import {
    MasterDataItem,
    MasterDataTypeResponse,
    UpdateMasterDataBody,
} from '../../../model/interface/masterData'
import { MasterDataService } from '../../../service/masterData.service'
import { DataTypeMaster, mapData } from '../typemaster'
import { notificationMessage } from '../../../components/notification'
import ConfirmationModal, {
    ActionType,
} from '../../../components/confirmationModal'

export const mapToUpdateMasterDataBody = (
    masterData: MasterDataItem
): UpdateMasterDataBody => {
    return {
        typeId: masterData.typeId,
        masterDataName: masterData.masterDataName,
        description: masterData.description,
        banner: masterData.banner,
    }
}

export const EditMaster = (): JSX.Element => {
    const navigate = useNavigate()
    const [form] = Form.useForm()
    const { editID } = useParams()
    const [data, setData] = useState<DataTypeMaster[] | null>([])

    const pushDataCreate = async (params: UpdateMasterDataBody) => {
        try {
            if (editID) {
                await MasterDataService.updateMasterDataType(editID, params)
                navigate('/masterdata')
                notificationMessage({
                    type: 'success',
                    message: 'แก้ไข Master Data สำเร็จ',
                })
            } else {
                throw new Error('editID is null')
            }
        } catch (error) {
            let errorMessage: string = 'An unexpected error occurred'
            if (
                typeof error === 'object' &&
                error !== null &&
                'error' in error
            ) {
                errorMessage = (error as { error: string }).error
            }
            if (
                errorMessage ===
                'master data item with the same name already exists for the current type'
            ) {
                notificationMessage({
                    type: 'error',
                    message: 'ไม่สามารถสร้าง Master Data ที่ชื่อซ้ำกันได้',
                })
            } else {
                notificationMessage({
                    type: 'error',
                    message: 'แก้ไข Master Data ไม่สำเร็จ',
                })
            }
        }
    }

    const onSubmitForm = async (formData: UpdateMasterDataBody) => {
        ConfirmationModal({
            mode: 'update' as ActionType,
            title: 'Master Data',
            onOk: () => pushDataCreate(formData),
            okType: 'default',
        })
    }

    const fetchData = async () => {
        try {
            const responseData: MasterDataTypeResponse =
                await MasterDataService.getAllMasterDataType()
            const mappedData: DataTypeMaster[] | null = mapData(
                responseData.data
            )
            setData(mappedData)
        } catch (error) {
            console.error('Error fetching data:', error)
        }
    }

    const fetchDataByID = async (editID: string) => {
        try {
            const responseData: MasterDataItem =
                await MasterDataService.getMasterDataTypeByID(editID)
            const mappedData: UpdateMasterDataBody | null =
                mapToUpdateMasterDataBody(responseData)
            form.setFieldsValue(mappedData)
        } catch (error) {
            console.error('Error fetching data:', error)
        }
    }

    useEffect(() => {
        fetchData()
        if (editID !== undefined) {
            fetchDataByID(editID)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editID])

    return (
        <>
            <PageLayout
                title="แก้ไข Master Data"
                action={
                    <Space>
                        <Button
                            type="default"
                            onClick={() =>
                                ConfirmationModal({
                                    mode: 'cancelUpdate' as ActionType,
                                    title: 'Master Data',
                                    onOk: () => navigate(-1),
                                    okType: 'default',
                                })
                            }
                        >
                            ยกเลิก
                        </Button>
                        <Button type="primary" onClick={() => form.submit()}>
                            บันทึก
                        </Button>
                    </Space>
                }
            >
                <div>
                    <MasterDataForm
                        form={form}
                        onSubmit={onSubmitForm}
                        data={data}
                    />
                </div>
            </PageLayout>
        </>
    )
}
