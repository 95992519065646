interface PrefixAPI {
    identity: string
    content: string
    banner: string
    masterData: string
    upload: string
    product: string
    blog: string
    admin: string
    payment: string
    updateVerifying: string
    customer: string
}

export const prefixApi: PrefixAPI = {
    identity: '/auth',
    content: '/content',
    banner: '/banner',
    masterData: '/master-data',
    upload: '/upload',
    product: '/product',
    blog: '/blog',
    admin: '/admin',
    payment: '/payment',
    updateVerifying: '/update-verify',
    customer: '/customer',
}
