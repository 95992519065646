import React from 'react'
import PageLayout from '../../components/page-layout'
import { useAuth } from '../../context/Profile.context'

const HomePage = (): JSX.Element => {
    const { profile } = useAuth()
    return (
        <PageLayout title="หน้าหลัก">
            <h2>ยินดีต้อนรับ {profile?.displayName}</h2>
        </PageLayout>
    )
}

export default HomePage
