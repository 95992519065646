import React, { useEffect, useState } from 'react'

import { ProductApiResponse } from '../../../model/interface/product'

import { ProductService } from '../../../service/product.service'
import { Input, Flex, Select, Spin } from 'antd'
import { debounce } from 'lodash'
import { AllProductList } from './list'
import { MasterDataService } from '../../../service/masterData.service'

const AllProduct = (): JSX.Element => {
    const [dataSourceAllProduct, setDataSourceAllProduct] =
        useState<ProductApiResponse>()
    const [filterAllProduct, setFilterAllProduct] = useState({
        page: 1,
        pageSize: 10,
        search: '',
        id: '',
        pagination: false,
    })
    const [option, setOption] = useState<{ label: string; value: string }[]>()
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        fetchData()
    }, [filterAllProduct])

    const fetchData = async () => {
        try {
            setIsLoading(true)
            const product = await ProductService.getAllProduct(
                filterAllProduct.page,
                filterAllProduct.pageSize,
                filterAllProduct.search,
                filterAllProduct.id,
                filterAllProduct.pagination
            )
            setDataSourceAllProduct(product)
            setIsLoading(false)
        } catch (error) {
            console.error('Error fetching data:', error)
        }
    }

    useEffect(() => {
        const fetchCategoty = async () => {
            const categoryData =
                await MasterDataService.getMasterDataProductCategory()
            setOption(
                categoryData.data.map((item) => ({
                    label: item.masterDataName,
                    value: item.id,
                }))
            )
        }
        fetchCategoty()
    }, [])

    const { Search } = Input

    const onSearch = debounce((value: string) => {
        setFilterAllProduct((prev) => {
            return { ...prev, search: value }
        })
    }, 300)

    const onSelected = (value: string) => {
        setFilterAllProduct((prev) => {
            return { ...prev, id: value }
        })
    }

    const filterOption = (
        input: string,
        option?: { label: string; value: string }
    ) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())

    const handleChange = (e: { target: { value: string } }) => {
        const value = e.target.value
        onSearch(value)
    }

    return (
        <>
            <Flex gap={12}>
                <Select
                    style={{ width: '100%' }}
                    showSearch
                    placeholder="หมวดหมู่"
                    optionFilterProp="children"
                    onChange={onSelected}
                    filterOption={filterOption}
                    options={option}
                    allowClear
                />
                <Search
                    placeholder="ค้นหาชื่อสินค้า"
                    onChange={handleChange}
                    enterButton
                />
            </Flex>
            <h3>สินค้าทั้งหมด</h3>
            <Spin spinning={isLoading}>
                <AllProductList
                    dataSource={dataSourceAllProduct}
                    setFilterAllProduct={setFilterAllProduct}
                    fetchData={fetchData}
                />
            </Spin>
        </>
    )
}

export default AllProduct
