import React, { useEffect, useState } from 'react'
import PageLayout from '../../components/page-layout'
import { PlusOutlined } from '@ant-design/icons'
import { Button, Space } from 'antd'
import { useNavigate } from 'react-router'
import { RoutePath } from '../../model/enum/route-path'
import { BannerList } from './list'
import { BannerService } from '../../service/banner.service'
import { notificationMessage } from '../../components/notification'
import ConfirmationModal, {
    ActionType,
} from '../../components/confirmationModal'

const BannerPage = (): JSX.Element => {
    const navigate = useNavigate()
    const [isEditPriority, setIsEditPriority] = useState<boolean>(false)
    const [isCancel, setIsCancel] = useState<boolean>(false)
    const [isConfirm, setIsConfirm] = useState<boolean>(false)
    const [isMaxBanner, setIsMaxBanner] = useState<boolean>(false)
    const showConfirmCancel = () => {
        ConfirmationModal({
            mode: 'cancelUpdate' as ActionType,
            title: 'ลำดับ Banner',
            onOk: () => {
                setIsEditPriority(false)
                setIsCancel(true)
                fetchData()
            },
        })
    }

    const fetchData = async () => {
        try {
            const res = await BannerService.getBanner()
            setIsMaxBanner(res.data.length >= 5)
        } catch {
            notificationMessage({
                type: 'error',
                message: 'เกิดข้อผิดพลาดในการดึงข้อมูล Banner',
            })
        }
    }

    useEffect(() => {
        fetchData()
    }, [])

    const handleMaxBannerChange = (isMax: boolean) => {
        setIsMaxBanner(isMax)
    }

    const handleEditPriorityChange = (value: boolean) => {
        setIsEditPriority(value)
    }

    const handleConfirmChange = (value: boolean) => {
        setIsConfirm(value)
    }

    useEffect(() => {
        console.log('isMaxBanner changed:', isMaxBanner)
    }, [isMaxBanner])

    return (
        <PageLayout
            title="ระบบจัดการรูปภาพ Banner"
            action={
                <Space>
                    {isEditPriority == false && (
                        <>
                            <Button
                                onClick={() =>
                                    navigate(`/${RoutePath.BANNER}/create`)
                                }
                                disabled={isMaxBanner}
                                type="primary"
                            >
                                <PlusOutlined />
                                เพิ่มรูป Banner
                            </Button>
                            <Button
                                onClick={() =>
                                    setIsEditPriority(!isEditPriority)
                                }
                                type="primary"
                            >
                                แก้ไขลำดับ Banner
                            </Button>
                        </>
                    )}
                    {isEditPriority == true && (
                        <>
                            <Button onClick={() => showConfirmCancel()}>
                                ยกเลิก
                            </Button>
                            <Button
                                onClick={() => {
                                    setIsConfirm(true)
                                }}
                                type="primary"
                            >
                                ยืนยัน
                            </Button>
                        </>
                    )}
                </Space>
            }
        >
            <BannerList
                isEditPriority={isEditPriority}
                isCancel={isCancel}
                isConfirm={isConfirm}
                onMaxBannerChange={handleMaxBannerChange}
                onEditPriorityChange={handleEditPriorityChange}
                onConfirmChange={handleConfirmChange}
            />
        </PageLayout>
    )
}

export default BannerPage
