import React from 'react'
import useFormContent from './useFormContent'
import { Form, Input, Flex, Typography } from 'antd'
import { ContentField } from '../../../../model/enum/content-field'
import { ContentRequest } from '../../../../model/interface/request/contentRequest'
import InputDragUploadImg from '../../../../components/inputDragUploadImg'
import styled from 'styled-components'
import InputDetail from '../../../../components/inputDetail'

const FormContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    @media (max-width: 992px) {
        gap: 0px;
        align-items: center;
    }
`
const ContainerInput = styled(Flex)`
    width: 100%;
`
const ContentForm: React.FC = () => {
    const { form, initialValues, isCreate, confirmCreateEditContent } =
        useFormContent()

    const onFinish = async (values: ContentRequest) => {
        confirmCreateEditContent(isCreate, values)
    }

    return (
        <Form
            id="content-form"
            form={form}
            onFinish={onFinish}
            requiredMark={false}
            layout="vertical"
            initialValues={initialValues}
        >
            <Typography.Title level={5} style={{ margin: '4px 0' }}>
                Content
            </Typography.Title>
            <FormContainer>
                <ContainerInput>
                    <Form.Item
                        name={[ContentField.IMAGE_URL]}
                        rules={[
                            { required: true, message: 'กรุณาเลือกรูปภาพ' },
                        ]}
                        style={{ width: '100%' }}
                    >
                        <InputDragUploadImg
                            form={form}
                            name={ContentField.IMAGE_URL}
                            initialValue={initialValues?.imageUrl}
                            aspectRatioRecomment={'1:1'}
                            sizeRecomment={'200x200'}
                        />
                    </Form.Item>
                </ContainerInput>

                <ContainerInput vertical>
                    <Form.Item
                        name={[ContentField.TITLE]}
                        label="หัวข้อ Content"
                        rules={[{ required: true, message: 'กรุณากรอก Title' }]}
                    >
                        <Input placeholder="กรุณากรอกหัวข้อ Content" />
                    </Form.Item>
                    <Form.Item
                        name={[ContentField.DESCRIPTION]}
                        label="รายละเอียด Content"
                        rules={[
                            {
                                required: true,
                                message: 'กรุณากรอกรายละเอียดของ Content',
                            },
                        ]}
                    >
                        <InputDetail
                            form={form}
                            name={ContentField.DESCRIPTION}
                            placeholder="กรุณากรอกรายละเอียดของ Content"
                        />
                    </Form.Item>
                </ContainerInput>
            </FormContainer>
        </Form>
    )
}

export default ContentForm
