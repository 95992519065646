import { Button, Form, FormInstance, Input, Space } from 'antd'
import React from 'react'
// import { FieldData } from 'rc-field-form/lib/interface'
import { IAdmin, IAdminForm } from '../../../../model/interface/admin'
import { AdminField } from '../../../../model/enum/admin-field'

interface IAdminFormProps {
    form: FormInstance
    onSubmit: (data: IAdminForm) => void
    onFormChange?: (data: IAdminForm) => void
    data?: IAdminForm | null
    initialValues?: IAdmin
    mode: 'create' | 'edit'
}

export const AdminForm = (props: IAdminFormProps): JSX.Element => {
    const { form, onSubmit, initialValues, mode } = props
    const [isEditPassword, setIsEditPassword] = React.useState(false)

    const hookSubmit = (values: IAdminForm) => {
        onSubmit({
            ...values,
        })
    }

    if (mode == 'edit') {
        form.setFieldsValue({
            [AdminField.PASSWORD]: '',
        })
    }

    return (
        <>
            <Form
                layout="vertical"
                name="basic"
                requiredMark={false}
                form={form}
                initialValues={initialValues || {}}
                onFinish={hookSubmit}
                autoComplete="off"
            >
                <Form.Item
                    name={[AdminField.FIRST_NAME]}
                    label="ชื่อจริง Admin"
                    rules={[
                        { required: true, message: 'กรุณากรอกชื่อจริง Admin' },
                    ]}
                >
                    <Input placeholder="กรุณากรอกชื่อจริง Admin" />
                </Form.Item>
                <Form.Item
                    name={[AdminField.LAST_NAME]}
                    label="นามสกุล Admin"
                    rules={[
                        {
                            required: true,
                            message: 'กรุณากรอกนามสกุล Admin',
                        },
                    ]}
                >
                    <Input placeholder="กรุณากรอกนามสกุล Admin" />
                </Form.Item>
                <Form.Item
                    name={[AdminField.DISPLAY_NAME]}
                    label="Display Name"
                    rules={[
                        {
                            required: true,
                            message: 'กรุณากรอก Display Name',
                        },
                    ]}
                >
                    <Input placeholder="กรุณากรอก Display Name" />
                </Form.Item>
                <Form.Item
                    name={[AdminField.EMAIL]}
                    label="อีเมล"
                    initialValue={initialValues?.email}
                    rules={[
                        {
                            required: true,
                            message: 'กรุณากรอกอีเมล',
                        },
                        {
                            pattern: /^[a-z0-9._-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                            message:
                                'กรุณากรอกอีเมลเป็นตัวพิมพ์เล็กและรูปแบบที่ถูกต้อง',
                        },
                    ]}
                >
                    <Input placeholder="กรุณากรอกอีเมล" />
                </Form.Item>
                {mode === 'create' ? (
                    <>
                        <Form.Item
                            name={[AdminField.PASSWORD]}
                            label="รหัสผ่าน"
                            rules={[
                                {
                                    required: true,
                                    message: 'กรุณากรอกรหัสผ่าน',
                                },
                                {
                                    min: 8,
                                    message:
                                        'รหัสผ่านต้องมีความยาวอย่างน้อย 8 ตัวอักษร',
                                },
                                {
                                    pattern:
                                        /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/,
                                    message:
                                        'รหัสผ่านต้องประกอบด้วยตัวอักษรตัวใหญ่ และ ตัวเลข',
                                },
                            ]}
                        >
                            <Input
                                placeholder="กรุณากรอกกรอกรหัสผ่าน"
                                type="password"
                            />
                        </Form.Item>
                        <Form.Item
                            name={[AdminField.CONFIRM_PASSWORD]}
                            label="ยืนยันรหัสผ่าน"
                            rules={[
                                {
                                    required: true,
                                    message: 'กรุณากรอกรหัสผ่านยืนยัน',
                                },
                                {
                                    validator: (_, value) => {
                                        if (
                                            !value ||
                                            form.getFieldValue(
                                                AdminField.PASSWORD
                                            ) === value
                                        ) {
                                            return Promise.resolve()
                                        }
                                        return Promise.reject(
                                            'รหัสผ่านไม่ตรงกัน'
                                        )
                                    },
                                },
                            ]}
                        >
                            <Input
                                placeholder="กรุณากรอกกรอกรหัสผ่านยืนยัน"
                                type="password"
                            />
                        </Form.Item>
                    </>
                ) : (
                    <>
                        {isEditPassword ? (
                            <Space
                                style={{
                                    display: 'flex',
                                    justifyContent: 'end',
                                }}
                            >
                                <Button
                                    type="dashed"
                                    onClick={() => {
                                        setIsEditPassword(false)
                                        form.setFieldsValue({
                                            [AdminField.OLD_PASSWORD]: '',
                                            [AdminField.PASSWORD]: '',
                                            [AdminField.CONFIRM_PASSWORD]: '',
                                        })
                                    }}
                                    style={{ color: 'red' }}
                                >
                                    ยกเลิกการแก้ไขรหัสผ่าน
                                </Button>
                            </Space>
                        ) : (
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'end',
                                }}
                            >
                                <Button
                                    type="primary"
                                    onClick={() => {
                                        setIsEditPassword(true)
                                    }}
                                >
                                    แก้ไขรหัสผ่าน
                                </Button>
                            </div>
                        )}
                        {isEditPassword ? (
                            <>
                                <Form.Item
                                    name={[AdminField.OLD_PASSWORD]}
                                    label="รหัสผ่านเดิม"
                                    rules={[
                                        {
                                            required:
                                                mode === 'edit' &&
                                                isEditPassword == true,
                                            message: 'กรุณากรอกรหัสผ่านเดิม',
                                        },
                                    ]}
                                >
                                    <Input
                                        placeholder="กรุณากรอกรหัสผ่าน"
                                        type="password"
                                    />
                                </Form.Item>
                                <Form.Item
                                    name={[AdminField.PASSWORD]}
                                    label="รหัสผ่านใหม่"
                                    rules={[
                                        {
                                            required:
                                                mode === 'edit' &&
                                                isEditPassword == true,
                                            message: 'กรุณากรอกรหัสผ่าน',
                                        },
                                        {
                                            min: 8,
                                            message:
                                                'รหัสผ่านต้องมีความยาวอย่างน้อย 8 ตัวอักษร',
                                        },
                                        {
                                            pattern:
                                                /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/,
                                            message:
                                                'รหัสผ่านต้องประกอบด้วยตัวอักษรตัวใหญ่ และ ตัวเลข',
                                        },
                                    ]}
                                >
                                    <Input
                                        placeholder="กรุณากรอกกรอกรหัสผ่าน"
                                        type="password"
                                    />
                                </Form.Item>
                                <Form.Item
                                    name={[AdminField.CONFIRM_PASSWORD]}
                                    label="ยืนยันรหัสผ่านใหม่"
                                    rules={[
                                        {
                                            required:
                                                mode === 'edit' &&
                                                isEditPassword == true,
                                            message: 'กรุณากรอกรหัสผ่านยืนยัน',
                                        },
                                        {
                                            validator: (_, value) => {
                                                if (
                                                    !value ||
                                                    form.getFieldValue(
                                                        AdminField.PASSWORD
                                                    ) === value
                                                ) {
                                                    return Promise.resolve()
                                                }
                                                return Promise.reject(
                                                    'รหัสผ่านไม่ตรงกัน'
                                                )
                                            },
                                        },
                                    ]}
                                >
                                    <Input
                                        placeholder="กรุณากรอกกรอกรหัสผ่านยืนยัน"
                                        type="password"
                                    />
                                </Form.Item>
                            </>
                        ) : (
                            <Form.Item label="รหัสผ่าน">
                                <Input disabled placeholder="*********" />
                            </Form.Item>
                        )}
                    </>
                )}
            </Form>
        </>
    )
}
