import React from 'react'
import { Form, Input, InputNumber, Radio, Select, Space } from 'antd'
import useFormProduct from './useFormProduct'
import ProductItemform from './components/form/productItemForm'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import InputDragUploadImg from './components/input/inputDragUploadImg'
import InputPicCardUploadImg from './components/input/inputPicCardUploadImg'
import { ProductRequest } from '../../../../model/interface/product'
import InputDetail from '../../../../components/inputDetail'

interface ProductFormProps {}

const ProductForm: React.FC<ProductFormProps> = ({}) => {
    const { form, isCreate, initialValues, options, confirmCreateEditProduct } =
        useFormProduct()

    const onFinish = async (values: ProductRequest) => {
        confirmCreateEditProduct(
            isCreate,
            values,
            <ExclamationCircleOutlined />
        )
    }

    return (
        <Form
            id="product-form"
            form={form}
            onFinish={onFinish}
            requiredMark={false}
            layout="vertical"
            initialValues={
                initialValues
                    ? { ...initialValues }
                    : {
                          lineOAOption: true,
                          productItem: [{ choiceItem: [{}] }],
                      }
            }
        >
            <Form.Item
            className='custom-form-upload'
                style={{ display: 'flex', justifyContent: 'center' }}
                name={['banner']}
                label="รูปภาพ Banner"
                rules={[
                    {
                        required: true,
                        message: 'กรุณาอัพโหลดรูปภาพ Banner',
                    },
                ]}
            >
                <InputDragUploadImg
                    form={form}
                    name={'banner'}
                    initialValue={initialValues?.banner}
                    forMobile={false}
                />
            </Form.Item>
            <Form.Item
            className='custom-form-upload'
                style={{ display: 'flex', justifyContent: 'center' }}
                name={['bannerMobile']}
                label="รูปภาพ Banner สำหรับโทรศัพท์ (ไม่บังคับ)"
            >
                <InputDragUploadImg
                    form={form}
                    name={'bannerMobile'}
                    initialValue={initialValues?.bannerMobile}
                    forMobile={true}
                />
            </Form.Item>
            <Form.Item
            className='custom-form-upload'
                style={{ display: 'flex', justifyContent: 'center' }}
                name={['orderBanner']}
                label="รูปภาพ Order Banner Detail"
                rules={[
                    {
                        required: true,
                        message: 'กรุณาอัพโหลดรูปภาพ Order Banner Detail',
                    },
                ]}
            >
                <InputDragUploadImg
                    form={form}
                    name={'orderBanner'}
                    initialValue={initialValues?.orderBanner}
                    forMobile={false}
                />
            </Form.Item>
            <Form.Item
                name={['otherImageUrl']}
                label="รูปภาพอื่นๆ อัพโหลดได้สูงสุด 2 รูป (ไม่บังคับ)"
                style={{ display: 'flex', justifyContent: 'center' }}
            >
                <InputPicCardUploadImg
                    form={form}
                    name={'otherImageUrl'}
                    initialValue={initialValues?.otherImageUrl}
                    maxImg={2}
                />
            </Form.Item>
            <Form.Item
                name={['optionImageUrl']}
                label="รูปภาพหน้าตัวเลือก อัพโหลดได้สูงสุด 1 รูป (ไม่บังคับ)"
                style={{ display: 'flex', justifyContent: 'center' }}
            >
                <InputPicCardUploadImg
                    form={form}
                    name={'optionImageUrl'}
                    initialValue={initialValues?.optionImageUrl}
                    maxImg={1}
                />
            </Form.Item>
            <Form.Item
                name={['lineOAOption']}
                label="ตัวเลือก Line OA"
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                }}
            >
                <Radio.Group style={{ width: '507px' }}>
                    <Space direction="vertical">
                        <Radio value={true}>มีตัวเลือก Line OA</Radio>
                        <Radio value={false}>ไม่มีตัวเลือก Line OA</Radio>
                    </Space>
                </Radio.Group>
            </Form.Item>
            <Form.Item
                name={['title']}
                label="ชื่อสินค้า"
                rules={[
                    {
                        required: true,
                        message: 'กรุณากรอกชื่อของชื่อสินค้า',
                    },
                ]}
            >
                <Input placeholder="กรุณากรอกชื่อของชื่อสินค้า" />
            </Form.Item>
            <Form.Item
                name={['description']}
                label="รายละเอียดสินค้า"
                rules={[
                    {
                        required: true,
                        message: 'กรุณากรอกรายละเอียดของสินค้า',
                    },
                ]}
            >
                <InputDetail
                    form={form}
                    name={'description'}
                    placeholder="กรุณากรอกรายละเอียดของสินค้า"
                />
            </Form.Item>
            <Form.Item
                name={['categoryId']}
                label="หมวดหมู่"
                rules={[
                    {
                        required: true,
                        message: 'กรุณากรอกหมวดหมู่ของสินค้า',
                    },
                ]}
            >
                <Select
                    showSearch
                    placeholder="กรุณาเลือกหมวดหมู่ของสินค้า"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                        (option?.label ?? '').includes(input)
                    }
                    filterSort={(optionA, optionB) =>
                        (optionA?.label ?? '')
                            .toLowerCase()
                            .localeCompare((optionB?.label ?? '').toLowerCase())
                    }
                    options={options}
                />
            </Form.Item>
            <Form.Item
                name={['price']}
                label="ราคา"
                rules={[
                    {
                        required: true,
                        message: 'กรุณากรอกราคาของสินค้า',
                    },
                ]}
            >
                <InputNumber
                    style={{ width: '100%' }}
                    placeholder="กรุณากรอกราคาของสินค้า"
                />
            </Form.Item>
            <Form.Item
                name={['orderDetail']}
                label="รายละเอียดคำสั่งซื้อสินค้า"
                rules={[
                    {
                        required: true,
                        message: 'กรุณากรอกรายละเอียดของคำสั่งซื้อสินค้า',
                    },
                ]}
            >
                <InputDetail
                    form={form}
                    name={'orderDetail'}
                    placeholder="กรุณากรอกรายละเอียดของคำสั่งซื้อสินค้า"
                />
            </Form.Item>
            <Form.Item
                name={['paymentDetail']}
                label="รายละเอียดการชำระเงิน"
                rules={[
                    {
                        required: true,
                        message: 'กรุณากรอกรายละเอียดของการชำระเงิน',
                    },
                ]}
            >
                <InputDetail
                    form={form}
                    name={'paymentDetail'}
                    placeholder="กรุณากรอกรายละเอียดของการชำระเงิน"
                />
            </Form.Item>

            <Form.List name={['productItem']}>
                {(fields, operations) => (
                    <ProductItemform
                        form={form}
                        fields={fields}
                        operations={operations}
                    />
                )}
            </Form.List>
        </Form>
    )
}

export default ProductForm
